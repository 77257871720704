import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import UserLogin from '@/views/auth/UserLogin.vue'
import GetPasswordResetLink from '@/views/auth/GetPasswordResetLink.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import MeetingDetails from '@/views/meetings/MeetingDetails.vue'
import MeetingsList from '@/views/meetings/MeetingsList.vue'
import MeetingItemsList from '@/views/meetings/MeetingItemsList.vue'
import MeetingPlayer from '@/views/meetings/MeetingPlayer.vue'
import MeetingReport from '@/views/meetings/MeetingReport.vue'
import MeetingReportPrint from '@/views/meetings/MeetingReportPrint.vue'
import ConsultationModulesList from '@/views/consultation_modules/ConsultationModulesList.vue'
import FormSchemaList from '@/views/form_management/FormSchemaList.vue'
import CustomersList from '@/views/customers/CustomersList.vue'
import { isAuthenticated } from '@/utils'
import SettingsIndex from '@/views/settings/SettingsIndex.vue'
import ChangePassword from '@/views/settings/ChangePassword.vue'
import UserDeletion from '@/views/settings/UserDeletion.vue'
import { i18n } from '@/i18n'
import SlideDeckList from '@/views/slide_decks/SlideDeckList.vue'
import SlideDeckDetails from '@/views/slide_decks/SlideDeckDetails.vue'
import AddSlideDeckAndVersion from '@/views/slide_decks/AddSlideDeckAndVersion.vue'
import SlideDeckVersionList from '@/views/slide_decks/SlideDeckVersionList.vue'
import AddSlideDeckVersion from '@/views/slide_decks/AddSlideDeckVersion.vue'
import MeetingFormsList from '@/views/meetings/MeetingFormsList.vue'
import HashedMeetingFormView from '@/views/form_management/HashedMeetingFormView.vue'
import MeetingFormView from '@/views/form_management/MeetingFormView.vue'
import MeetingFormPrintView from '@/views/form_management/MeetingFormPrintView.vue'
import MeetingEmotionAnalysis from '@/views/meetings/MeetingEmotionAnalysis.vue'
import CustomerDetail from '@/views/customers/CustomerDetail.vue'
import MeetingTemplatesList from '@/views/meeting_templates/MeetingTemplatesList.vue'
import MeetingTemplateDetails from '@/views/meeting_templates/MeetingTemplateDetails.vue'
import Registration from '@/views/auth/Registration.vue'
import ActivateAccount from '@/views/auth/ActivateAccount.vue'
import AggregatedAnalysis from '@/views/analysis/AggregatedAnalysis.vue'
import {
  MeetingFormPrintViewProps,
  MeetingFormsListProps,
  MeetingFormViewProps,
  MeetingItemsListProps,
  MeetingTemplateFormPrintViewProps,
  MeetingTemplateFormsListProps,
  MeetingTemplateFormViewProps,
  MeetingTemplateItemsListProps
} from '@/router/fixedProps'
import AppContent from '@/components/base/AppContent.vue'
import UserToken from '@/views/auth/UserToken.vue'
import ErrorPage from '@/views/auth/ErrorPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      if (isAuthenticated()) {
        return '/meetings'
      }

      return '/auth'
    }
  },
  {
    path: '/auth',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'meta.pageTitles.user_login'
    }
  },
  {
    path: '/auth/reset',
    name: 'GetPasswordResetLink',
    component: GetPasswordResetLink,
    meta: {
      title: 'meta.pageTitles.get_password_reset_link'
    }
  },
  {
    path: '/auth/reset_confirm/:uid/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'meta.pageTitles.reset_password'
    },
    props: true
  },
  {
    path: '/auth/token',
    name: 'UserToken',
    component: UserToken,
    meta: {
      title: 'meta.pageTitles.user_login'
    }
  },
  {
    path: '/auth/error/:error',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
      title: 'meta.pageTitles.error_page'
    },
    props: (route) => ({
      error: route.params.error
    })
  },
  {
    path: '/meetings',
    name: 'MeetingContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'MeetingsList',
        component: MeetingsList,
        meta: {
          title: 'meta.pageTitles.meetings_list'
        }
      },
      {
        path: 'add',
        name: 'MeetingAdd',
        component: MeetingDetails,
        meta: {
          title: 'meta.pageTitles.meeting_add'
        }
      },
      {
        path: ':id/change',
        name: 'MeetingChange',
        component: MeetingDetails,
        meta: {
          title: 'meta.pageTitles.meeting_details'
        },
        props: (route) => ({
          meetingId: Number(route.params.id)
        })
      },
      {
        path: ':id/items',
        name: 'MeetingItemsList',
        component: MeetingItemsList,
        meta: {
          title: 'meta.pageTitles.meeting_items_list'
        },
        props: (route) => ({
          objectId: Number(route.params.id),
          ...MeetingItemsListProps
        })
      },
      {
        path: ':id/forms',
        name: 'MeetingFormsList',
        component: MeetingFormsList,
        meta: {
          title: 'meta.pageTitles.meeting_forms_list'
        },
        props: (route) => ({
          objectId: Number(route.params.id),
          ...MeetingFormsListProps
        })
      },
      {
        path: ':id/player',
        name: 'MeetingPlayer',
        component: MeetingPlayer,
        meta: {
          title: 'meta.pageTitles.meeting_player'
        },
        props: (route) => ({
          meetingId: Number(route.params.id)
        })
      },
      {
        path: ':objectId/form/:meetingFormId',
        name: 'MeetingFormView',
        component: MeetingFormView,
        meta: {
          title: 'meta.pageTitles.meeting_form_view'
        },
        props: (route) => ({
          meetingFormId: Number(route.params.meetingFormId),
          objectId: Number(route.params.objectId),
          ...MeetingFormViewProps
        })
      },
      {
        path: ':objectId/form/:meetingFormId/print',
        name: 'MeetingFormPrintView',
        component: MeetingFormPrintView,
        meta: {
          title: 'meta.pageTitles.meeting_form_view'
        },
        props: (route) => ({
          meetingFormId: Number(route.params.meetingFormId),
          objectId: Number(route.params.objectId),
          ...MeetingFormPrintViewProps
        })
      },
      {
        path: ':id/report',
        name: 'MeetingReport',
        component: MeetingReport,
        meta: {
          title: 'meta.pageTitles.meeting_report'
        },
        props: (route) => ({
          meetingId: Number(route.params.id)
        })
      },
      {
        path: ':id/report/print',
        name: 'MeetingReportPrint',
        component: MeetingReportPrint,
        meta: {
          title: 'meta.pageTitles.meeting_report_print'
        },
        props: (route) => ({
          meetingId: Number(route.params.id)
        })
      },
      {
        path: ':id/emotion-analysis',
        name: 'MeetingEmotionAnalysis',
        component: MeetingEmotionAnalysis,
        meta: {
          title: 'meta.pageTitles.meeting_emotion_analysis'
        },
        props: (route) => ({
          meetingId: Number(route.params.id)
        })
      }
    ]
  },
  {
    path: '/meeting_form/:hash',
    name: 'HashedMeetingFormView',
    component: HashedMeetingFormView,
    meta: {
      title: 'meta.pageTitles.meeting_form_view'
    },
    props: true
  },
  {
    path: '/meeting_form/:hash/print',
    name: 'HashedMeetingFormPrintView',
    component: MeetingFormPrintView,
    meta: {
      title: 'meta.pageTitles.meeting_form_view'
    },
    props: true
  },
  {
    path: '/consultation-modules',
    name: 'ConsultationModulesContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'ConsultationModulesList',
        component: ConsultationModulesList,
        meta: {
          title: 'meta.pageTitles.consultation_modules'
        }
      }
    ]
  },
  {
    path: '/forms',
    name: 'FormSchemaContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'FormSchemaList',
        component: FormSchemaList,
        meta: {
          title: 'meta.pageTitles.forms_list'
        }
      }
    ]
  },
  {
    path: '/customers',
    name: 'CustomerContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'CustomersList',
        component: CustomersList,
        meta: {
          title: 'meta.pageTitles.customers_list'
        }
      },
      {
        path: 'add',
        name: 'CustomersAdd',
        component: CustomerDetail,
        meta: {
          title: 'meta.pageTitles.customer_add'
        }
      },
      {
        path: ':id/change',
        name: 'CustomersChange',
        component: CustomerDetail,
        meta: {
          title: 'meta.pageTitles.customer_details'
        },
        props: (route) => ({
          customerId: Number(route.params.id)
        })
      }
    ]
  },
  {
    path: '/settings',
    name: 'SettingsContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'SettingsIndex',
        component: SettingsIndex,
        meta: {
          title: 'meta.pageTitles.settings_index'
        }
      },
      {
        path: 'change_password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
          title: 'meta.pageTitles.change_password'
        }
      },
      {
        path: 'user_deletion',
        name: 'UserDeletion',
        component: UserDeletion,
        meta: {
          title: 'meta.pageTitles.userDeletion'
        }
      }
    ]
  },
  {
    path: '/slide_decks',
    name: 'SlideDeckContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'SlideDecks',
        component: SlideDeckList,
        meta: {
          title: 'meta.pageTitles.slide_decks'
        }
      },
      {
        path: 'add',
        name: 'AddSlideDeckAndVersion',
        component: AddSlideDeckAndVersion,
        meta: {
          title: 'meta.pageTitles.slide_deck_add'
        }
      },
      {
        path: ':slideDeckId/change',
        name: 'SlideDeckDetails',
        component: SlideDeckDetails,
        meta: {
          title: 'meta.pageTitles.slide_deck_change'
        },
        props: true
      },
      {
        path: ':slideDeckId/add',
        name: 'AddSlideDeckVersion',
        component: AddSlideDeckVersion,
        meta: {
          title: 'meta.pageTitles.slide_deck_version_add'
        },
        props: true
      },
      {
        path: ':id/versions',
        name: 'SlideDeckVersionList',
        component: SlideDeckVersionList,
        meta: {
          title: 'meta.pageTitles.slide_deck_version_list'
        }
      }
    ]
  },
  {
    path: '/meeting_templates',
    name: 'MeetingTemplateContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'MeetingTemplatesList',
        component: MeetingTemplatesList,
        meta: {
          title: 'meta.pageTitles.meeting_templates_list'
        }
      },
      {
        path: 'add',
        name: 'MeetingTemplateAdd',
        component: MeetingTemplateDetails,
        meta: {
          title: 'meta.pageTitles.meeting_template_add'
        }
      },
      {
        path: ':id/change',
        name: 'MeetingTemplateChange',
        component: MeetingTemplateDetails,
        meta: {
          title: 'meta.pageTitles.meeting_template_details'
        },
        props: (route) => ({
          meetingTemplateId: Number(route.params.id)
        })
      },
      {
        path: ':id/items',
        name: 'MeetingTemplateItemsList',
        component: MeetingItemsList,
        meta: {
          title: 'meta.pageTitles.meeting_template_items_list'
        },
        props: (route) => ({
          objectId: Number(route.params.id),
          ...MeetingTemplateItemsListProps
        })
      },
      {
        path: ':id/forms',
        name: 'MeetingTemplateFormsList',
        component: MeetingFormsList,
        meta: {
          title: 'meta.pageTitles.meeting_template_forms_list'
        },
        props: (route) => ({
          objectId: Number(route.params.id),
          ...MeetingTemplateFormsListProps
        })
      },
      {
        path: ':objectId/form/:meetingFormId',
        name: 'MeetingTemplateFormView',
        component: MeetingFormView,
        meta: {
          title: 'meta.pageTitles.meeting_template_form_view'
        },
        props: (route) => ({
          meetingFormId: Number(route.params.meetingFormId),
          objectId: Number(route.params.objectId),
          ...MeetingTemplateFormViewProps
        })
      },
      {
        path: ':objectId/form/:meetingFormId/print',
        name: 'MeetingTemplateFormPrintView',
        component: MeetingFormPrintView,
        meta: {
          title: 'meta.pageTitles.meeting_template_form_view'
        },
        props: (route) => ({
          meetingFormId: Number(route.params.meetingFormId),
          objectId: Number(route.params.objectId),
          ...MeetingTemplateFormPrintViewProps
        })
      }
    ]
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: {
      title: 'meta.pageTitles.registration'
    }
  },
  {
    path: '/auth/activate/:uid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount,
    meta: {
      title: 'meta.pageTitles.activate_account'
    }
  },
  {
    path: '/aggregated_analysis',
    name: 'AggregatedAnalysisContainer',
    component: AppContent,
    children: [
      {
        path: '',
        name: 'AggregatedAnalysis',
        component: AggregatedAnalysis,
        meta: {
          title: 'meta.pageTitles.aggregated_analysis'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'CatchAll',
    redirect: '/'
  }
]

const pathsWhichNeedNoAuthentication = [
  'Home',
  'UserLogin',
  'GetPasswordResetLink',
  'ResetPassword',
  'UserToken',
  'CatchAll',
  'HashedMeetingFormView',
  'HashedMeetingFormPrintView',
  'Registration',
  'ActivateAccount',
  'ErrorPage'
]

const siteTitle = 'Mataono'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let name = ''
  if (to.name) {
    name = String(to.name)
  }
  if (name === 'UserLogin' && isAuthenticated()) {
    next('/meetings')
  } else if (pathsWhichNeedNoAuthentication.includes(name)) {
    next()
  } else {
    if (isAuthenticated()) {
      next()
    } else {
      next('/auth')
    }
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = i18n.global.t(String(nearestWithTitle.meta.title)) + ' | ' + siteTitle
  } else {
    document.title = siteTitle
  }
})

router.afterEach((to, from) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window._paq === undefined || to.meta.analyticsIgnore) {
    return
  }

  const url = router.resolve(to.fullPath).href
  const referrerUrl = from && from.fullPath
    ? router.resolve(from.fullPath).href
    : undefined

  if (referrerUrl) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setReferrerUrl', referrerUrl])
  }
  if (url) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setCustomUrl', url])
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['setDocumentTitle', document.title])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['trackPageView'])
})

export default router
