import { ActionContext } from 'vuex'
import { State } from '@/store/state'
import http from '@/http-common'
import {
  AddMeetingItemData,
  AddSlideDeckData,
  AggregatedEmotionAnalysisRestData,
  ConsultationModule,
  Customer,
  CustomReportTemplate,
  EventType,
  FormSchema,
  GenerateTestDataResult,
  HashedMeetingForm,
  InitialReportData,
  Meeting,
  MeetingEmotionAnalysisData,
  MeetingForm,
  MeetingFormFilterSpecialPurpose,
  MeetingFormFromRest,
  MeetingFormState,
  MeetingFromRest,
  MeetingItem,
  MeetingItemFromRest,
  MeetingTemplate,
  MeetingTemplateDetailsFormData,
  MeetingTemplateFromRest,
  MeetingVideo,
  MeetingVideoPerson,
  MoodEventData,
  PerContentAggregatedEmotionAnalysisRestRequestParams,
  Profile,
  ProfileUser,
  Report,
  Slide,
  SlideDeck,
  SlideDeckFromRest,
  SlideDeckVersion,
  SlideFromRest,
  Topic,
  TourObject,
  User,
  withoutCompanyFilterMagicValue,
  WorkIncapacityItemData,
  Workspace
} from '@/types/descript_meeting_rest'
import { Filter, AlertType, RequestIdentificationData, TourObjectData } from '@/types/internal'
import { compareCustomers, compareFilters, compareStrings, getCustomerName, setWorkspaceInformation } from '@/utils'
import { FreehandNoteData } from '@/types/freehand_note'
import { getOnBoardingTours } from '@/components/onboarding/tours'
import { i18n } from '@/i18n'

function isFreehandNoteData (data: unknown): data is FreehandNoteData {
  return (data as FreehandNoteData).canvasWidth !== undefined && (data as FreehandNoteData).pointGroups !== undefined
}

function ensureValidFreehandNoteData (meetingItemFreeHandNoteJson: unknown): FreehandNoteData {
  if (isFreehandNoteData(meetingItemFreeHandNoteJson)) {
    return meetingItemFreeHandNoteJson
  }

  return {
    canvasWidth: 0,
    pointGroups: []
  }
}

function ensureValidMeetingItem (meetingItemFromRest: MeetingItemFromRest): MeetingItem {
  return {
    id: meetingItemFromRest.id,
    meeting: meetingItemFromRest.meeting,
    order: meetingItemFromRest.order,
    content_type: meetingItemFromRest.content_type,
    content_object_id: meetingItemFromRest.content_object_id,
    like_status: meetingItemFromRest.like_status,
    note: meetingItemFromRest.note,
    free_hand_note_vector: meetingItemFromRest.free_hand_note_vector,
    free_hand_note_json: ensureValidFreehandNoteData(
      meetingItemFromRest.free_hand_note_json),
    group_key: meetingItemFromRest.group_key,
    serialized_specific_meeting_item: meetingItemFromRest.serialized_specific_meeting_item,
    links: meetingItemFromRest.links
  }
}

function createMeetingObjectListFromResponse (meetingsFromRest: Array<MeetingFromRest>): Array<Meeting> {
  const meetings: Array<Meeting> = []
  for (const restMeeting of meetingsFromRest) {
    meetings.push(new Meeting(restMeeting))
  }
  return meetings
}

function createMeetingTemplateObjectListFromResponse (meetingsFromRest: Array<MeetingTemplateFromRest>): Array<MeetingTemplate> {
  const meetingTemplates: Array<MeetingTemplate> = []
  for (const restMeetingTemplate of meetingsFromRest) {
    meetingTemplates.push(new MeetingTemplate(restMeetingTemplate))
  }
  return meetingTemplates
}

function ensureValidMeetingItems (meetingItemsFromRest: Array<MeetingItemFromRest>): Array<MeetingItem> {
  const validMeetingsItems = [] as Array<MeetingItem>

  for (const meetingItemFromRest of meetingItemsFromRest) {
    validMeetingsItems.push(
      ensureValidMeetingItem(
        meetingItemFromRest))
  }

  return validMeetingsItems
}

async function freehandSvgDataUriToFile (meetingItemId: number, svgDataUri: string | null): Promise<File | string> {
  if (svgDataUri !== null) {
    return fetch(svgDataUri).then(
      function (response) {
        return response.arrayBuffer()
      }
    ).then(
      function (buffer) {
        return new File(
          [buffer],
          'freehand_note-' + meetingItemId.toString() + '.svg', { type: 'image/svg+xml' })
      }
    )
  }

  return new Promise((resolve) => {
    resolve('')
  })
}

function ensureValidMeetingForm (meetingFormFromRest: MeetingFormFromRest): MeetingForm {
  return {
    id: meetingFormFromRest.id,
    meeting: meetingFormFromRest.meeting,
    title: meetingFormFromRest.title,
    form_schema: meetingFormFromRest.form_schema, // we should put proper datatype definition here like for the meeting item
    form_ui_schema: meetingFormFromRest.form_ui_schema,
    answers: meetingFormFromRest.answers,
    valid_until: meetingFormFromRest.valid_until,
    state: meetingFormFromRest.state,
    hash: meetingFormFromRest.hash
  }
}

function ensureValidMeetingForms (meetingFormsFromRest: Array<MeetingFormFromRest>): Array<MeetingForm> {
  const validMeetingsForms = [] as Array<MeetingForm>

  for (const meetingFormFromRest of meetingFormsFromRest) {
    validMeetingsForms.push(
      ensureValidMeetingForm(
        meetingFormFromRest))
  }

  return validMeetingsForms
}

function ensureValidSlide (slideFromRest: SlideFromRest): Slide {
  return new Slide(slideFromRest)
}

function ensureValidSlides (slidesFromRest: Array<SlideFromRest>): Array<Slide> {
  const validSlides = [] as Array<Slide>

  for (const slideFromRest of slidesFromRest) {
    validSlides.push(
      ensureValidSlide(
        slideFromRest))
  }

  return validSlides
}

function ensureValidSlideDeck (slideDeckFromRest: SlideDeckFromRest): SlideDeck {
  return {
    id: slideDeckFromRest.id,
    title: slideDeckFromRest.title,
    description: slideDeckFromRest.description,
    topics: slideDeckFromRest.topics,
    is_deleted: slideDeckFromRest.is_deleted,
    first_slide_of_slide_deck: new Slide(slideDeckFromRest.first_slide_of_slide_deck)
  }
}

function ensureValidSlideDecks (slideDecksFromRest: Array<SlideDeckFromRest>): Array<SlideDeck> {
  const validSlideDecks = [] as Array<SlideDeck>

  for (const slideDeckFromRest of slideDecksFromRest) {
    validSlideDecks.push(
      ensureValidSlideDeck(
        slideDeckFromRest))
  }

  return validSlideDecks
}

export const actions = {
  /**
   * Makes a call to the api to get all available meetings and determines all
   * possible filter options and fills the availableMeetingsFilter attribute
   * of the store.
   */
  async getAvailableMeetingsFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const response = await http.get('meeting/meeting/')

    const customerIds: Array<number> = []
    const customers: Array<Customer> = []
    let includeCustomerIsNoneOption = false
    const locations: Array<string> = []
    const topicIds: Array<number> = []
    const meetings = createMeetingObjectListFromResponse(response.data)

    for (const meeting of meetings) {
      if (
        meeting.customer &&
        customerIds.indexOf(meeting.customer) === -1 &&
        meeting.customer_serialized
      ) {
        customerIds.push(meeting.customer)
        customers.push(meeting.customer_serialized)
      } else if (meeting.customer === null) {
        includeCustomerIsNoneOption = true
      }

      if (meeting.location && locations.indexOf(meeting.location) === -1) {
        locations.push(meeting.location)
      }

      if (meeting.topics.length > 0) {
        for (const topicId of meeting.topics) {
          if (topicIds.indexOf(topicId) === -1) {
            topicIds.push(topicId)
          }
        }
      }
    }

    const availableMeetingsFilter: Array<Filter> = []

    availableMeetingsFilter.push({ group: 'start', key: 'start', value: 'only_future' })
    availableMeetingsFilter.push({ group: 'start', key: 'start', value: 'only_past' })

    if (includeCustomerIsNoneOption) {
      availableMeetingsFilter.push(
        {
          group: 'customer',
          key: 'no_customer',
          value: 'true',
          label: i18n.global.t('filter_values_verbose.meeting_has_no_customer')
        }
      )
    }

    customers.sort(compareCustomers)

    for (const customer of customers) {
      availableMeetingsFilter.push(
        { group: 'customer', key: 'customer', value: customer.id.toString(), label: getCustomerName(customer) }
      )
    }

    locations.sort(compareStrings)

    for (const location of locations) {
      availableMeetingsFilter.push({ group: 'location', key: 'location', value: location })
    }

    availableMeetingsFilter.push({ group: 'has_open_report', key: 'has_open_report', value: 'true' })
    availableMeetingsFilter.push({ group: 'has_open_report', key: 'has_open_report', value: 'false' })

    for (const topicId of topicIds) {
      const topicLabel = await context.dispatch('getTopicName', topicId)
      availableMeetingsFilter.push(
        { group: 'topics', key: 'topics', value: topicId.toString(), label: topicLabel }
      )
    }

    context.commit('setAvailableMeetingsFilter', availableMeetingsFilter)
    context.commit('ensureValidityOfMeetingsFilter')

    return availableMeetingsFilter
  },
  /**
   * Set a new search term to use when retrieving the instances for a list of meetings
   * as identified by the provided list key. The action sets the new search term and
   * updates the instances of the list.
   */
  async setMeetingsSearchTerm (
    context: ActionContext<State, State>,
    payload: { listKey: string, searchTerm: string }
  ): Promise<Array<Meeting>> {
    context.commit('ensureMeetingsList', { listKey: payload.listKey })
    context.commit('setMeetingsSearchTerm', payload)
    return context.dispatch('getMeetings', payload.listKey)
  },
  /**
   * Reset the search term to use when retrieving the instances for a list of meetings
   * as identified by the provided list key. The action removes the current search term
   * and updates the instances of the list.
   */
  async resetMeetingsSearchTerm (context: ActionContext<State, State>, listKey: string): Promise<Array<Meeting>> {
    context.commit('ensureMeetingsList', { listKey: listKey })
    context.commit('setMeetingsSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getMeetings', listKey)
  },
  /**
   * Add a new active filter to use when retrieving the instances for a list of meetings
   * as identified by the provided list key. The action adds the new active filter and
   * updates the instances of the list.
   */
  async addMeetingsFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter, onlyOnListInit: boolean, doNotUpdateList?: boolean }
  ): Promise<Array<Meeting>> {
    if (!payload.onlyOnListInit ||
          !Object.prototype.hasOwnProperty.call(context.state.meetingsWithSearchAndFilterState, payload.listKey)) {
      context.commit('ensureMeetingsList', { listKey: payload.listKey })
      context.commit('addMeetingsFilter', payload)
    }

    if (payload.doNotUpdateList) {
      return context.state.meetingsWithSearchAndFilterState[payload.listKey].instances
    }

    return context.dispatch('getMeetings', payload.listKey)
  },
  /**
   * Remove an active filter currently used when retrieving the instances for a list of
   * meetings as identified by the provided list key. The action removes the given filter
   * and updates the instances of the list.
   */
  async removeMeetingsFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Meeting> {
    context.commit('ensureMeetingsList', { listKey: payload.listKey })
    context.commit('removeMeetingsFilter', payload)
    return context.dispatch('getMeetings', payload.listKey)
  },
  /**
   * Removes all active filters currently used when retrieving the instances for a list of
   * meetings as identified by the provided list key. Reset the only from future flag also
   * to true. The action removes all the filters, sets the boolean flag and updates the
   * instances of the list.
   */
  async resetMeetingsFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<Meeting>> {
    context.commit('ensureMeetingsList', { listKey: listKey })
    context.commit('resetMeetingsFilter', listKey)
    return context.dispatch('getMeetings', listKey)
  },
  /**
   * Retrieves the instances for a list of meetings as identified by the provided list key.
   * The action pays attention to a set search term and active filters if they are existing
   * for the given list key. The retrieved instances are stored into meetingsWithSearchAndFilterState
   * attribute of the store under the given list key.
   */
  async getMeetings (context: ActionContext<State, State>, listKey: string): Promise<Array<Meeting>> {
    context.commit('ensureMeetingsList', { listKey: listKey })

    const meetings = context.state.meetingsWithSearchAndFilterState[listKey]
    const params: {
      search?: string,
      customer?: string,
      // eslint-disable-next-line camelcase
      no_customer?: string,
      location?: string,
      // eslint-disable-next-line camelcase
      start_gte?: string,
      // eslint-disable-next-line camelcase
      start_lte?: string,
      topics?: string, // since the model field is plural this should also be kept plural
      ordering?: string,
      // eslint-disable-next-line camelcase
      has_open_report?: string,
      // eslint-disable-next-line camelcase
      has_viewable_emotion_analysis?: string,
      owner?: string
    } = {
      // Order meetings by default descending to their start date.
      ordering: '-start'
    }

    if (meetings.searchTerm) {
      params.search = `${meetings.searchTerm}`
    }

    if (meetings.filters.length > 0) {
      for (const filter of meetings.filters) {
        if (filter.key === 'customer') {
          params.customer = filter.value
        }

        if (filter.key === 'no_customer') {
          params.no_customer = filter.value
        }

        if (filter.key === 'location') {
          params.location = filter.value
        }

        if (filter.key === 'start' && filter.value === 'only_future') {
          params.start_gte = (new Date()).toISOString()
          // when viewing only future meetings the next meeting should be the first meeting in list
          params.ordering = 'start'
        }

        if (filter.key === 'start' && filter.value === 'only_past') {
          params.start_lte = (new Date()).toISOString()
        }

        if (filter.key === 'topics') {
          params.topics = filter.value
        }

        if (filter.key === 'has_open_report') {
          params.has_open_report = filter.value
        }

        if (filter.key === 'has_viewable_emotion_analysis') {
          params.has_viewable_emotion_analysis = filter.value
        }

        if (filter.key === 'owner') {
          params.owner = filter.value
        }
      }
    }

    const response = await http.get(`meeting/${meetings.urlPart}/`, { params: params })
    const restMeetings = createMeetingObjectListFromResponse(response.data)
    context.commit(
      'setMeetings',
      {
        listKey: listKey,
        meetings: restMeetings
      })
    return restMeetings
  },
  async getNextMeeting (context: ActionContext<State, State>): Promise<Meeting | null> {
    const params = {
      start_gte: (new Date()).toISOString(),
      ordering: 'start'
    }
    const response = await http.get('meeting/meeting/', { params: params })
    let nextMeeting = null
    if (response.data.length > 0) {
      nextMeeting = new Meeting(response.data[0])
    }
    context.commit('setNextMeeting', nextMeeting)
    return nextMeeting
  },
  async getPastOpenReportMeeting (context: ActionContext<State, State>): Promise<Array<Meeting>> {
    const listKey = 'pastOpenReportMeetingsList'
    context.commit('ensureMeetingsList', { listKey: listKey })

    const params = {
      start_lte: (new Date()).toISOString(),
      ordering: '-start',
      has_open_report: 'true'
    }
    const response = await http.get('meeting/meeting/', { params: params })
    const restMeetings = createMeetingObjectListFromResponse(response.data)
    context.commit(
      'setMeetings',
      {
        listKey: listKey,
        meetings: restMeetings
      })
    return restMeetings
  },
  async getMeetingById (context: ActionContext<State, State>, meetingId: number): Promise<Meeting> {
    const response = await http.get('meeting/meetingreadonly/' + meetingId.toString() + '/')
    return new Meeting(response.data)
  },
  async removeMeeting (context: ActionContext<State, State>, payload: Meeting): Promise<Meeting | null> {
    context.commit('removeMeeting', payload)
    await http.delete('meeting/meeting/' + payload.id + '/')
    context.commit('addImmediateAlert', { msg: 'base.deleted', type: AlertType.Success })
    await context.dispatch('getAvailableMeetingsFilter')
    return context.dispatch('getNextMeeting')
  },
  async getMeetingFormById (context: ActionContext<State, State>, meetingFormId: number): Promise<MeetingForm> {
    const response = await http.get(`meeting/meetingform/${meetingFormId.toString()}/`)
    return response.data
  },
  async getMeetingFormByHash (context: ActionContext<State, State>, hash: string): Promise<HashedMeetingForm> {
    const response = await http.get(`meeting/formhashed/${hash}/`)
    setWorkspaceInformation(response.data.workspace_information)
    // Coming from the server response data does not match the HashedMeetingForm type.
    // We need the workspace information only to write it to the local storage once and delete it from the data
    // object afterwards to meet the type requirements
    delete response.data.workspace_information
    return response.data
  },
  async storeMeetingFormAnswers (
    context: ActionContext<State, State>,
    payload: { meetingFormId: number, data: Record<string, unknown> }
  ): Promise<void> {
    await http.patch(
      `meeting/meetingform/${payload.meetingFormId}/`,
      JSON.stringify({ answers: payload.data }, null, 2))
  },
  async storeMeetingFormTitle (
    context: ActionContext<State, State>,
    payload: { meetingFormId: number, title: string }
  ): Promise<void> {
    await http.patch(
      `meeting/meetingform/${payload.meetingFormId}/`,
      JSON.stringify({ title: payload.title }, null, 2))
  },
  async setMeetingFormSaved (
    context: ActionContext<State, State>,
    meetingFormId: number
  ): Promise<void> {
    await http.patch(
      `meeting/meetingform/${meetingFormId}/`,
      JSON.stringify({ state: MeetingFormState.Finished }, null, 2))
  },
  async storeHashedMeetingFormAnswers (
    context: ActionContext<State, State>,
    payload: { meetingFormHash: string, data: Record<string, unknown> }
  ): Promise<void> {
    await http.patch(
      `meeting/formhashed/${payload.meetingFormHash}/`,
      JSON.stringify({ answers: payload.data }, null, 2))
  },
  async setHashedMeetingFormSaved (context: ActionContext<State, State>, meetingFormHash: string): Promise<void> {
    await http.patch(
      `meeting/formhashed/${meetingFormHash}/`,
      JSON.stringify({ state: MeetingFormState.Finished }, null, 2))
  },
  getTopicName: async function (context: ActionContext<State, State>, topicId: number): Promise<string> {
    if (context.state.topics.length === 0) {
      await context.dispatch('getTopics')
    }

    for (const topic of context.state.topics) {
      if (topic.id === topicId) {
        return topic.name
      }
    }

    return topicId.toString()
  },
  /**
   * Makes a call to the api to get all available slide decks and determines all
   * possible filter options and fills the availableSlideDeckFilter attribute
   * of the store.
   */
  async getAvailableSlideDeckFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const response = await http.get('meeting/slidedeck/')

    const topicIds: Array<number> = []
    const topicFilters: Array<Filter> = []

    for (const slideDeck of response.data) {
      for (const topicId of slideDeck.topics) {
        if (topicIds.indexOf(topicId) === -1) {
          topicIds.push(topicId)
          const topicName = await context.dispatch('getTopicName', topicId)
          topicFilters.push({ group: 'topics', key: 'topics', value: topicId.toString(), label: topicName })
        }
      }
    }

    topicFilters.sort(compareFilters)

    context.commit('setAvailableSlideDeckFilter', topicFilters)
    context.commit('ensureValidityOfSlideDecksFilter')

    return topicFilters
  },
  /**
   * Set a new search term to use when retrieving the instances for a list of slide decks
   * as identified by the provided list key. The action sets the new search term and
   * updates the instances of the list.
   */
  async setSlideDecksSearchTerm (
    context: ActionContext<State, State>,
    payload: { listKey: string, searchTerm: string }
  ): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', payload.listKey)
    context.commit('setSlideDecksSearchTerm', payload)
    return context.dispatch('getSlideDecks', payload.listKey)
  },
  /**
   * Reset the search term to use when retrieving the instances for a list of slide decks
   * as identified by the provided list key. The action removes the current search term
   * and updates the instances of the list.
   */
  async resetSlideDecksSearchTerm (context: ActionContext<State, State>, listKey: string): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', listKey)
    context.commit('setSlideDecksSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getSlideDecks', listKey)
  },
  /**
   * Add a new active filter to use when retrieving the instances for a list of slide decks
   * as identified by the provided list key. The action adds the new active filter and
   * updates the instances of the list.
   */
  async addSlideDecksFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', payload.listKey)
    context.commit('addSlideDecksFilter', payload)
    return context.dispatch('getSlideDecks', payload.listKey)
  },
  /**
   * Remove an active filter currently used when retrieving the instances for a list of
   * slide decks as identified by the provided list key. The action removes the given filter
   * and updates the instances of the list.
   */
  async removeSlideDecksFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', payload.listKey)
    context.commit('removeSlideDecksFilter', payload)
    return context.dispatch('getSlideDecks', payload.listKey)
  },
  /**
   * Removes all active filters currently used when retrieving the instances for a list of
   * slide decks as identified by the provided list key. The action removes all the filters
   * and updates the instances of the list.
   */
  async resetSlideDecksFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', listKey)
    context.commit('resetSlideDecksFilter', listKey)
    return context.dispatch('getSlideDecks', listKey)
  },
  /**
   * Retrieves the instances for a list of slide decks as identified by the provided list key.
   * The action pays attention to a set search term and active filters if they are existing
   * for the given list key. The retrieved instances are stored into slideDecksWithSearchAndFilterState
   * attribute of the store under the given list key.
   */
  async getSlideDecks (context: ActionContext<State, State>, listKey: string): Promise<Array<SlideDeck>> {
    context.commit('ensureSlideDecksList', listKey)

    const slideDecks = context.state.slideDecksWithSearchAndFilterState[listKey]
    const params: { search?: string, topics?: string } = {}

    if (slideDecks.searchTerm) {
      params.search = `${slideDecks.searchTerm}`
    }

    if (slideDecks.filters.length > 0) {
      for (const filter of slideDecks.filters) {
        if (filter.key === 'topics') {
          params.topics = filter.value
        }
      }
    }
    const response = await http.get('meeting/slidedeck/', { params: params })
    context.commit(
      'setSlideDecks',
      {
        listKey: listKey,
        slideDecks: ensureValidSlideDecks(response.data)
      })

    return response.data
  },
  async addSlideDeck (context: ActionContext<State, State>, payload: AddSlideDeckData): Promise<SlideDeck> {
    const response = await http.post('meeting/slidedeck/', JSON.stringify(payload, null, 2))
    return ensureValidSlideDeck(response.data)
  },
  async storeSlideDeck (context: ActionContext<State, State>, slideDeck: SlideDeck): Promise<SlideDeck> {
    const response = await http.put(
      `meeting/slidedeck/${slideDeck.id}/`,
      JSON.stringify(slideDeck, null, 2))
    return ensureValidSlideDeck(response.data)
  },
  async getSlideDeckById (context: ActionContext<State, State>, slideDeckId: number): Promise<SlideDeck> {
    const response = await http.get('meeting/slidedeck/' + slideDeckId.toString() + '/')
    return ensureValidSlideDeck(response.data)
  },
  /**
   * adds a new SlideDeckVersion to a SlideDeck object
   *
   * @example construct a FormData object like so:
   *  const formData = new FormData()
   *  formData.append('field_name', formValues.field_name)
   */
  async addSlideDeckVersion (context: ActionContext<State, State>, payload: FormData): Promise<SlideDeckVersion> {
    const response = await http.post('meeting/slidedeckversion/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  },
  async getLatestVersionOfSlideDeck (
    context: ActionContext<State, State>,
    slideDeckId: number
  ): Promise<SlideDeckVersion> {
    const params = {
      slide_deck: slideDeckId,
      ordering: '-id'
    }
    const response = await http.get('meeting/slidedeckversion/', { params: params })
    return response.data[0]
  },
  async getSlideDeckVersions (
    context: ActionContext<State, State>,
    slideDeckId: number
  ): Promise<Array<SlideDeckVersion>> {
    const params = {
      slide_deck: slideDeckId,
      ordering: '-id'
    }
    const response = await http.get('meeting/slidedeckversion/', { params: params })
    return response.data
  },
  /**
   * Marks a slide deck as deleted, since we will keep SlideDecks in the db and only show the user decks
   * which marked as deleted. This function will also reload the SlideDecks and their available filters
   */
  async markSlideDeckAsDeleted (context: ActionContext<State, State>, payload: SlideDeck): Promise<Array<SlideDeck>> {
    context.commit('removeSlideDeck', payload.id)
    await http.patch('meeting/slidedeck/' + payload.id + '/', JSON.stringify(
      { is_deleted: true }, null, 2))
    context.commit('addImmediateAlert', { msg: 'base.deleted', type: AlertType.Success })

    await context.dispatch('getAvailableSlideDeckFilter')

    return context.dispatch('getSlideDecks', '')
  },
  /**
   * Calls a view action on to generate test data consisting of some concluded meetings.
   */
  async generateTestData (context: ActionContext<State, State>, payload: SlideDeck): Promise<GenerateTestDataResult> {
    return (await http.get('meeting/slidedeck/' + payload.id + '/generate_test_data/')).data
  },
  /**
   * Truly deletes a slide deck object. This is used when something went wrong during the api call composition when
   * creating a slide deck and its initial version.
   * This method should only be used internally and not exposed to the user in any way. The user should only be able
   * to mark slide decks as deleted.
   */
  async removeSlideDeck (context: ActionContext<State, State>, slideDeckId: number): Promise<void> {
    context.commit('removeSlideDeck', slideDeckId)
    await http.delete(`meeting/slidedeck/${slideDeckId}/`)
  },
  async getTopics (context: ActionContext<State, State>): Promise<Array<Topic>> {
    const response = await http.get('meeting/topic/')
    context.commit('setTopics', response.data)
    return response.data
  },
  async getTopicsWithSearch (context: ActionContext<State, State>, searchTerm: string): Promise<Array<Topic>> {
    const params: { search?: string } = {}
    if (searchTerm) {
      params.search = searchTerm
    }
    const response = await http.get('meeting/topic/', { params: params })
    return response.data
  },
  async getReports (context: ActionContext<State, State>): Promise<Array<Report>> {
    const response = await http.get('meeting/report/')
    context.commit('setReports', response.data)
    return response.data
  },
  async getReportForMeeting (context: ActionContext<State, State>, meetingId: number): Promise<Report | null> {
    const params = {
      meeting: meetingId.toString()
    }
    const response = await http.get('meeting/report/', { params: params })
    if (response.data.length > 0) {
      return response.data[0]
    } else {
      return null
    }
  },
  async createReportForMeeting (context: ActionContext<State, State>, meetingId: number): Promise<Report> {
    const data = { meeting: meetingId, content: '' }
    const response = await http.post('meeting/report/', JSON.stringify(data, null, 2))
    return response.data
  },
  async ensureReportForMeeting (context: ActionContext<State, State>, meetingId: number): Promise<Report> {
    // This action ensures the existence of an instance of the model Report
    // for the given Meeting model instance id as long as no formal problems
    // like missing authentication are there. This has been done to allow for
    // easier implementation of the Vue.js component which deals with the report
    // of a meeting: It is now be written under the assumption that there is
    // always a report object and no if / else casing is needed except for in
    // this action. To differentiate between an open and edited report the
    // value of report.content can be looked at for now.

    const report = await context.dispatch('getReportForMeeting', meetingId)

    if (report) {
      return report
    }

    return await context.dispatch('createReportForMeeting', meetingId)
  },
  async getItemsForMeeting (context: ActionContext<State, State>, meetingId: number): Promise<Array<MeetingItem>> {
    const params = {
      meeting: meetingId.toString()
    }
    const response = await http.get('meeting/meetingitem/', { params: params })
    return ensureValidMeetingItems(response.data)
  },
  async addMeetingItem (context: ActionContext<State, State>, payload: AddMeetingItemData): Promise<MeetingItem> {
    const response = await http.post('meeting/meetingitem/', JSON.stringify(payload, null, 2))
    return ensureValidMeetingItem(response.data)
  },

  async getAvailableFormSchemaFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const response = await http.get('meeting/formschema/')

    const topicIds: Array<number> = []
    const topicFilters: Array<Filter> = []

    for (const form of response.data) {
      for (const topicId of form.topics) {
        if (topicIds.indexOf(topicId) === -1) {
          topicIds.push(topicId)
          const topicName = await context.dispatch('getTopicName', topicId)
          topicFilters.push({ group: 'topics', key: 'topics', value: topicId.toString(), label: topicName })
        }
      }
    }

    topicFilters.sort(compareFilters)

    context.commit('setAvailableFormSchemaFilter', topicFilters)
    context.commit('ensureValidityOfFormSchemasFilter')

    return topicFilters
  },
  async setFormSchemasSearchTerm (
    context: ActionContext<State, State>,
    payload: { listKey: string, searchTerm: string }
  ): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', payload.listKey)
    context.commit('setFormSchemasSearchTerm', payload)
    return context.dispatch('getFormSchemas', payload.listKey)
  },
  async getFormSchemas (context: ActionContext<State, State>, listKey: string): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', listKey)
    const forms = context.state.formSchemasWithSearchAndFilterState[listKey]
    const params: { search?: string, topics?: string } = {}
    if (forms.searchTerm) {
      params.search = `${forms.searchTerm}`
    }
    if (forms.filters.length > 0) {
      for (const filter of forms.filters) {
        if (filter.key === 'topics') {
          params.topics = filter.value
        }
      }
    }
    const response = await http.get('meeting/formschema/', { params: params })
    context.commit(
      'setFormSchemas',
      {
        listKey: listKey,
        formSchemas: response.data
      })

    return response.data
  },
  async addFormSchemasFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', payload.listKey)
    context.commit('addFormSchemasFilter', payload)
    return context.dispatch('getFormSchemas', payload.listKey)
  },
  async resetFormSchemasSearchTerm (
    context: ActionContext<State, State>,
    listKey: string
  ): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', listKey)
    context.commit('setFormSchemasSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getFormSchemas', listKey)
  },
  async removeFormSchemasFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', payload.listKey)
    context.commit('removeFormSchemasFilter', payload)
    return context.dispatch('getFormSchemas', payload.listKey)
  },
  async resetFormSchemasFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<FormSchema>> {
    context.commit('ensureFormSchemasList', listKey)
    context.commit('resetFormSchemasFilter', listKey)
    return context.dispatch('getFormSchemas', listKey)
  },

  async storeMeetingItem (
    context: ActionContext<State, State>,
    payload: { meetingItemId: number, attributesToUpdate: Record<string, unknown> }
  ): Promise<MeetingItem> {
    const response = await http.patch(
      `meeting/meetingitem/${payload.meetingItemId}/`,
      JSON.stringify(payload.attributesToUpdate, null, 2))
    return ensureValidMeetingItem(response.data)
  },
  async storeMeetingItemFreehandNote (
    context: ActionContext<State, State>,
    payload: { meetingItemId: number, vector: string | null, data: FreehandNoteData }
  ): Promise<MeetingItem> {
    const freehandSvgDataFile = await freehandSvgDataUriToFile(payload.meetingItemId, payload.vector)
    const formData = new FormData()
    formData.append(
      'free_hand_note_vector',
      freehandSvgDataFile)
    formData.append(
      'free_hand_note_json',
      JSON.stringify(payload.data, null, 2))
    const response = await http.patch(
      `meeting/meetingitem/${payload.meetingItemId}/`,
      formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    return ensureValidMeetingItem(response.data)
  },
  /**
   * This deletes a given meeting item. It returns the list of remaining meeting
   * items for the corresponding meeting with already corrected order attribute.
   *
   * @example
   * this.$store.dispatch('removeMeetingItem', meetingItem).then(
          (remainingMeetingItems) => {
            this.meetingItems = remainingMeetingItems
          }, errorHelper((error) => {
            // error handling
          }))
   */
  async removeMeetingItem (
    context: ActionContext<State, State>,
    meetingItem: MeetingItem
  ): Promise<Array<MeetingItem>> {
    const response = await http.delete(`meeting/meetingitem/${meetingItem.id}/`)
    return ensureValidMeetingItems(response.data)
  },
  async getEventsForMeeting (context: ActionContext<State, State>, meetingId: number): Promise<Array<Event>> {
    const params = {
      meeting: meetingId,
      ordering: 'created'
    }
    const response = await http.get('meeting/event/', { params: params })
    return response.data
  },
  /**
   * This is just a helper to avoid duplicating the RESP api request boilerplate.
   * Without very good reason it should never be directly called by a component.
   *
   */
  async createEvent (context: ActionContext<State, State>, data: unknown): Promise<Event> {
    const response = await http.post('meeting/event/', JSON.stringify(data, null, 2))
    return response.data
  },
  /**
   * creates Meeting starting event
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventMeetingStarted (context: ActionContext<State, State>, meetingId: number): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingId,
        created: new Date().toISOString(),
        type: EventType.MeetingStarted
      })
  },
  /**
   * creates Meeting ending event
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventMeetingEnded (context: ActionContext<State, State>, meetingId: number): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingId,
        created: new Date().toISOString(),
        type: EventType.MeetingEnded
      })
  },
  /**
   * creates Event for flipping through meeting items
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFlippedTo (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FlippedTo,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for liking a meeting items
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventLiked (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.Liked,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for removing like from meeting items
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventLikeRemoved (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.LikeRemoved,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for disliking meeting items
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventDisliked (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.Disliked,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for removing a dislike of a meeting item
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventDislikeRemoved (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.DislikeRemoved,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for adding a note to a meeting item
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventNoteAdded (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.NoteAdded,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for editing meeting item notes
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventNoteEdited (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.NoteEdited,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for deleting a note of a meeting item
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventNoteDeleted (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.NoteDeleted,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for entering free hand note mode in the meeting player view
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFreeHandNoteModeActivated (
    context: ActionContext<State, State>, meetingItem: MeetingItem
  ): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FreeHandNoteModeActivated,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for saving a free hand note in the meeting player view
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFreeHandNoteSave (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FreeHandNoteSave,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for resetting the free hand note in the meeting player view
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFreeHandNoteReset (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FreeHandNoteReset,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for deleting a free hand not in the meeting player view
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFreeHandNoteDelete (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FreeHandNoteDelete,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for leaving free hand note mode in the meeting player view
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventFreeHandNoteModeDeactivated (
    context: ActionContext<State, State>, meetingItem: MeetingItem
  ): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.FreeHandNoteModeDeactivated,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for given consent to recording of meeting for emotion analysis
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventConsentGiven (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.ConsentGiven,
        item: meetingItem.id
      })
  },
  /**
   * creates Event for refused consent to recording of meeting for emotion analysis
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventConsentRefused (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.ConsentRefused,
        item: meetingItem.id
      })
  },
  /**
   * creates event for finished work incapacity item
   *
   * One action per event type on purpose because then we can use TypeScript
   * to annotate the parameters and prevent the creation of events with invalid
   * combination of attributes like a meeting start with set meeting item.
   */
  async createEventWorkIncapacityItemFinished (context: ActionContext<State, State>, payload: { meetingItem: MeetingItem, data: WorkIncapacityItemData }): Promise<Event> {
    return context.dispatch(
      'createEvent',
      {
        meeting: payload.meetingItem.meeting,
        created: new Date().toISOString(),
        type: EventType.WorkIncapacityItemFinished,
        item: payload.meetingItem.id,
        extra_data: payload.data
      })
  },
  /**
   * Calls a view action to generate mood events for a meeting which gets marked as finished by the meeting player.
   */
  async generateMoodEvents (context: ActionContext<State, State>, meetingId: number): Promise<GenerateTestDataResult> {
    return (await http.get('meeting/meeting/' + meetingId + '/generate_mood_events/')).data
  },
  async getSlideById (context: ActionContext<State, State>, slideId: number): Promise<Slide> {
    const response = await http.get('meeting/slide/' + slideId.toString() + '/')
    return ensureValidSlide(response.data)
  },
  async resolveSlideDeckVersionFirstSlide (
    context: ActionContext<State, State>, slideDeckVersionId: number
  ): Promise<Slide> {
    const params = {
      slide_deck_version: slideDeckVersionId.toString(),
      slide_number: '1'
    }
    const response = await http.get('meeting/slide/', { params: params })
    return ensureValidSlide(response.data[0])
  },
  /**
   * Requests all Slides to a SlideDeckVersion
   */
  async getSlidesForSlideDeckVersion (
    context: ActionContext<State, State>, slideDeckVersionId: number
  ): Promise<Array<Slide>> {
    const params = {
      slide_deck_version: slideDeckVersionId,
      ordering: 'slide_number'
    }
    const response = await http.get('meeting/slide/', { params: params })
    return ensureValidSlides(response.data)
  },
  async getMeetingForms (context: ActionContext<State, State>, listKey: string): Promise<Array<MeetingForm>> {
    context.commit('ensureMeetingFormsList', listKey)
    const params: {
      search?: string,
      meeting?: string,
      // eslint-disable-next-line camelcase
      meeting__meeting__customer?: string
    } = {}

    const meetingForms = context.state.meetingFormsWithSearchAndFilterState[listKey]

    if (meetingForms.searchTerm) {
      params.search = `${meetingForms.searchTerm}`
    }
    if (meetingForms.filters.length > 0) {
      for (const filter of meetingForms.filters) {
        if (filter.key === 'meeting__meeting__customer') {
          params.meeting__meeting__customer = filter.value
        }
        if (filter.key === 'meeting') {
          params.meeting = filter.value
        }
      }
    }
    const response = await http.get('meeting/meetingform/', { params: params })
    const meetingFormsRest = ensureValidMeetingForms(response.data)
    context.commit('setMeetingForms', {
      listKey: listKey,
      meetingForms: meetingFormsRest
    })
    return response.data
  },
  async addMeetingFormsFilter (
    context: ActionContext<State, State>, payload: { listKey: string, filter: Filter }
  ): Promise<Array<MeetingForm>> {
    context.commit('ensureMeetingFormsList', payload.listKey)
    context.commit('addMeetingFormsFilter', payload)
    return context.dispatch('getMeetingForms', payload.listKey)
  },
  /**
   * Set a new search term to use when retrieving the instances for a list of meeting forms
   * as identified by the provided list key. The action sets the new search term and
   * updates the instances of the list.
   */
  async setMeetingFormsSearchTerm (
    context: ActionContext<State, State>, payload: { listKey: string, searchTerm: string }
  ): Promise<Array<MeetingForm>> {
    context.commit('ensureMeetingFormsList', payload.listKey)
    context.commit('setMeetingFormsSearchTerm', payload)
    return context.dispatch('getMeetingForms', payload.listKey)
  },
  /**
   * resets a new search term to use when retrieving the instances for a list of meeting forms
   * as identified by the provided list key. The action sets the new search term and
   * updates the instances of the list.
   */
  async resetMeetingFormsSearchTerm (
    context: ActionContext<State, State>, listKey: string
  ): Promise<Array<MeetingForm>> {
    context.commit('ensureMeetingFormsList', listKey)
    context.commit('setMeetingFormsSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getMeetingForms', listKey)
  },
  async changeMeetingFormState (
    context: ActionContext<State, State>,
    payload: { meetingForm: MeetingForm, state: MeetingFormState, listKey: string }
  ): Promise<MeetingForm> {
    const response = await http.patch(
      `meeting/meetingform/${payload.meetingForm.id}/`,
      JSON.stringify({ state: payload.state }, null, 2))
    await context.dispatch('getMeetingForms', payload.listKey)
    return response.data
  },
  async setMeetingFormsValidUntilDate (
    context: ActionContext<State, State>,
    payload: { listKey: string, meetingFormId: number, date: string }
  ): Promise<Array<MeetingForm>> {
    await http.patch(`meeting/meetingform/${payload.meetingFormId}/`, JSON.stringify({ valid_until: payload.date }, null, 2))
    return context.dispatch('getMeetingForms', payload.listKey)
  },
  async setMeetingFormsTitle (
    context: ActionContext<State, State>,
    payload: { listKey: string, meetingFormId: number, title: string }
  ): Promise<Array<MeetingForm>> {
    await http.patch(`meeting/meetingform/${payload.meetingFormId}/`, JSON.stringify({ title: payload.title }, null, 2))
    return context.dispatch('getMeetingForms', payload.listKey)
  },
  /**
   * Retrieves emotion analysis data which have been calculated by the backend.
   */
  async emotionAnalysisData (
    context: ActionContext<State, State>, payload: { meetingId: number, rollingWindowSize: number }
  ): Promise<MeetingEmotionAnalysisData> {
    const params = {
      rolling_window_size: payload.rollingWindowSize
    }
    const response = await http.get(`meeting/meetingreadonly/${payload.meetingId}/emotion_analysis_data/`, { params: params })
    return response.data
  },
  async getWorkspaceOfUser (context: ActionContext<State, State>): Promise<Workspace | null> {
    const response = await http.get('meeting/workspace/')

    if (response.data.length > 0) {
      setWorkspaceInformation(response.data[0])
      return response.data[0]
    }

    return null
  },
  async getCustomersOfWorkspace (context: ActionContext<State, State>, listKey: string): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', listKey)
    const customersOfKey = context.state.customersWithSearchAndFilterState[listKey]
    const params: {
      search?: string,
      company?: string
    } = {}

    if (customersOfKey.searchTerm) {
      params.search = customersOfKey.searchTerm
    }

    if (customersOfKey.filters.length > 0) {
      for (const filter of customersOfKey.filters) {
        if (filter.key === 'company') {
          params.company = filter.value
        }
      }
    }

    const response = await http.get('meeting/customer/', { params: params })

    context.commit(
      'setCustomers',
      {
        listKey: listKey,
        customers: response.data
      }
    )
    return response.data
  },
  async getCustomersOfWorkspaceWithSearch (
    context: ActionContext<State, State>,
    searchTerm: string
  ): Promise<Array<Customer>> {
    const response = await http.get('meeting/customer/', { params: { search: searchTerm } })
    return response.data
  },
  async setCustomersSearchTerm (
    context: ActionContext<State, State>, payload: { listKey: string, searchTerm: string }
  ): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', payload.listKey)
    context.commit('setCustomersSearchTerm', payload)
    return context.dispatch('getCustomersOfWorkspace', payload.listKey)
  },
  async resetCustomersSearchTerm (context: ActionContext<State, State>, listKey: string): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', listKey)
    context.commit('setCustomersSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getCustomersOfWorkspace', listKey)
  },
  async getAvailableCustomersFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const response = await http.get('meeting/customer/')

    const companies: Array<string> = []

    for (const customer of response.data) {
      if (companies.indexOf(customer.company) === -1) {
        companies.push(customer.company)
      }
    }

    const availableCustomersFilter: Array<Filter> = []

    for (const company of companies) {
      // Special case the non company value by not
      // inserting it before the sorting happens.
      if (company) {
        availableCustomersFilter.push(
          { group: 'company', key: 'company', value: company }
        )
      }
    }

    availableCustomersFilter.sort(compareFilters)

    if (companies.indexOf('') > -1) {
      availableCustomersFilter.unshift(
        {
          group: 'company',
          key: 'company',
          value: withoutCompanyFilterMagicValue,
          label: i18n.global.t('filter_values_verbose.customer_has_no_organization')
        }
      )
    }

    context.commit('setAvailableCustomersFilter', availableCustomersFilter)
    context.commit('ensureValidityOfCustomersFilter')

    return availableCustomersFilter
  },
  async removeCustomersFilter (
    context: ActionContext<State, State>, payload: { listKey: string, filter: Filter }
  ): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', payload.listKey)
    context.commit('removeCustomersFilter', payload)
    return context.dispatch('getCustomersOfWorkspace', payload.listKey)
  },
  async resetCustomersFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', listKey)
    context.commit('resetCustomersFilter', listKey)
    return context.dispatch('getCustomersOfWorkspace', listKey)
  },
  async addCustomersFilter (
    context: ActionContext<State, State>, payload: { listKey: string, filter: Filter }
  ): Promise<Array<Customer>> {
    context.commit('ensureCustomerList', payload.listKey)
    context.commit('addCustomersFilter', payload)
    return context.dispatch('getCustomersOfWorkspace', payload.listKey)
  },
  async getCustomerById (context: ActionContext<State, State>, id: number): Promise<Customer> {
    const response = await http.get(`meeting/customer/${id}/`)
    return response.data
  },
  async storeCustomer (
    context: ActionContext<State, State>, payload: { id: number, data: Customer }
  ): Promise<Customer> {
    const response = await http.patch(`meeting/customer/${payload.id}/`, JSON.stringify(payload.data))
    return response.data
  },
  async addCustomer (context: ActionContext<State, State>, data: Customer): Promise<Customer> {
    const response = await http.post('meeting/customer/', JSON.stringify(data))
    return response.data
  },
  /**
   * Makes a call to the api to get all available meeting templates and determines
   * all possible filter options and fills the availableMeetingTemplatesFilter
   * attribute of the store.
   */
  async getAvailableMeetingTemplatesFilter (context: ActionContext<State, State>): Promise<Array<Filter>> {
    const response = await http.get('meeting/meetingtemplate/')

    const locations: Array<string> = []
    const meetingTemplates = createMeetingTemplateObjectListFromResponse(response.data)
    for (const meetingTemplate of meetingTemplates) {
      if (meetingTemplate.location && locations.indexOf(meetingTemplate.location) === -1) {
        locations.push(meetingTemplate.location)
      }
    }

    const availableMeetingTemplatesFilter: Array<Filter> = []

    locations.sort(compareStrings)

    for (const location of locations) {
      availableMeetingTemplatesFilter.push({ group: 'location', key: 'location', value: location })
    }

    context.commit('setAvailableMeetingTemplatesFilter', availableMeetingTemplatesFilter)
    context.commit('ensureValidityOfMeetingTemplatesFilter')

    return availableMeetingTemplatesFilter
  },
  /**
   * Set a new search term to use when retrieving the instances for a list of meeting
   * templates as identified by the provided list key. The action sets the new search
   * term and updates the instances of the list.
   */
  async setMeetingTemplatesSearchTerm (
    context: ActionContext<State, State>,
    payload: { listKey: string, searchTerm: string }
  ): Promise<Array<MeetingTemplate>> {
    context.commit('ensureMeetingTemplatesList', payload.listKey)
    context.commit('setMeetingTemplatesSearchTerm', payload)
    return context.dispatch('getMeetingTemplates', payload.listKey)
  },
  /**
   * Reset the search term to use when retrieving the instances for a list of meeting
   * templates as identified by the provided list key. The action removes the current
   * search term and updates the instances of the list.
   */
  async resetMeetingTemplatesSearchTerm (context: ActionContext<State, State>, listKey: string): Promise<Array<MeetingTemplate>> {
    context.commit('ensureMeetingTemplatesList', listKey)
    context.commit('setMeetingTemplatesSearchTerm', { listKey: listKey, searchTerm: '' })
    return context.dispatch('getMeetingTemplates', listKey)
  },
  /**
   * Add a new active filter to use when retrieving the instances for a list of meeting
   * templates as identified by the provided list key. The action adds the new active
   * filter and updates the instances of the list.
   */
  async addMeetingTemplatesFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter, onlyOnListInit: boolean }
  ): Promise<Array<MeetingTemplate>> {
    if (!payload.onlyOnListInit ||
          !Object.prototype.hasOwnProperty.call(context.state.meetingTemplatesWithSearchAndFilterState, payload.listKey)) {
      context.commit('ensureMeetingTemplatesList', payload.listKey)
      context.commit('addMeetingTemplatesFilter', payload)
    }

    return context.dispatch('getMeetingTemplates', payload.listKey)
  },
  /**
   * Remove an active filter currently used when retrieving the instances for a list of
   * meeting templates as identified by the provided list key. The action removes the
   * given filter and updates the instances of the list.
   */
  async removeMeetingTemplatesFilter (
    context: ActionContext<State, State>,
    payload: { listKey: string, filter: Filter }
  ): Promise<Array<MeetingTemplate>> {
    context.commit('ensureMeetingTemplatesList', payload.listKey)
    context.commit('removeMeetingTemplatesFilter', payload)
    return context.dispatch('getMeetingTemplates', payload.listKey)
  },
  /**
   * Removes all active filters currently used when retrieving the instances for a list of
   * meeting templates as identified by the provided list key. The action removes all the
   * filters and updates the instances of the list.
   */
  async resetMeetingTemplatesFilter (context: ActionContext<State, State>, listKey: string): Promise<Array<MeetingTemplate>> {
    context.commit('ensureMeetingTemplatesList', listKey)
    context.commit('resetMeetingTemplatesFilter', listKey)
    return context.dispatch('getMeetingTemplates', listKey)
  },
  /**
   * Retrieves the instances for a list of meeting templates as identified by the provided
   * list key. The action pays attention to a set search term and active filters if they
   * are existing for the given list key. The retrieved instances are stored into
   * meetingTemplatesWithSearchAndFilterState attribute of the store under the given list key.
   */
  async getMeetingTemplates (context: ActionContext<State, State>, listKey: string): Promise<Array<MeetingTemplate>> {
    context.commit('ensureMeetingTemplatesList', listKey)

    const meetingTemplates = context.state.meetingTemplatesWithSearchAndFilterState[listKey]
    const params: {
      search?: string,
      location?: string,
    } = {}

    if (meetingTemplates.searchTerm) {
      params.search = `${meetingTemplates.searchTerm}`
    }

    if (meetingTemplates.filters.length > 0) {
      for (const filter of meetingTemplates.filters) {
        if (filter.key === 'location') {
          params.location = filter.value
        }
      }
    }

    const response = await http.get('meeting/meetingtemplate/', { params: params })
    const meetingTemplateObjects = createMeetingTemplateObjectListFromResponse(response.data)
    context.commit(
      'setMeetingTemplates',
      {
        listKey: listKey,
        meetingTemplates: meetingTemplateObjects
      })
    return meetingTemplateObjects
  },
  async getMeetingTemplateById (context: ActionContext<State, State>, meetingTemplateId: number): Promise<MeetingTemplate> {
    const response = await http.get('meeting/meetingtemplate/' + meetingTemplateId.toString() + '/')
    return new MeetingTemplate(response.data)
  },
  async removeMeetingTemplate (context: ActionContext<State, State>, payload: MeetingTemplate): Promise<void> {
    context.commit('removeMeetingTemplate', payload)
    await http.delete('meeting/meetingtemplate/' + payload.id + '/')
    context.commit('addImmediateAlert', { msg: 'base.deleted', type: AlertType.Success })
    await context.dispatch('getAvailableMeetingTemplatesFilter')
  },
  async storeMeetingTemplate (
    context: ActionContext<State, State>, payload: { id: number, data: MeetingTemplateDetailsFormData }
  ): Promise<MeetingTemplate> {
    const response = await http.patch(`meeting/meetingtemplate/${payload.id}/`, JSON.stringify(payload.data))
    return new MeetingTemplate(response.data)
  },
  async addMeetingTemplate (context: ActionContext<State, State>, data: MeetingTemplateDetailsFormData): Promise<MeetingTemplate> {
    const response = await http.post('meeting/meetingtemplate/', JSON.stringify(data))
    return new MeetingTemplate(response.data)
  },
  async addFormToMeeting (
    context: ActionContext<State, State>, payload: { objectId: number, formSchemaId: number }
  ): Promise<MeetingForm> {
    const response = await http.post(`meeting/meeting/${payload.objectId}/add_meeting_form/`, { form_schema_id: payload.formSchemaId })
    return response.data
  },
  async addFormToMeetingTemplate (
    context: ActionContext<State, State>, payload: { objectId: number, formSchemaId: number }
  ): Promise<MeetingForm> {
    const response = await http.post(`meeting/meetingtemplate/${payload.objectId}/add_meeting_form/`, { form_schema_id: payload.formSchemaId })
    return response.data
  },
  async removeMeetingForm (context: ActionContext<State, State>, meetingFormId: number): Promise<void> {
    context.commit('removeMeetingForm', meetingFormId)
    await http.delete(`meeting/meetingform/${meetingFormId}/`)
  },
  /**
   * Adds a new MeetingVideo object
   *
   * @example construct a FormData object like so:
   *  const formData = new FormData()
   *  formData.append('field_name', formValues.field_name)
   */
  async addMeetingVideo (context: ActionContext<State, State>, payload: FormData): Promise<MeetingVideo> {
    const response = await http.post('meeting/meetingvideo/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return response.data
  },
  async getMeetingVideo (context: ActionContext<State, State>, meetingId: number): Promise<MeetingVideo | null> {
    const params = {
      meeting: meetingId.toString()
    }
    const response = await http.get('meeting/meetingvideo/', { params: params })
    if (response.data.length > 0) {
      return response.data[0]
    } else {
      return null
    }
  },
  async getMeetingVideoPersonListByMeetingId (
    context: ActionContext<State, State>, meetingId: number
  ): Promise<Array<MeetingVideoPerson>> {
    const params = {
      meeting: meetingId.toString()
    }
    const personsResponse = await http.get('meeting/meetingvideoperson/', { params: params })
    const persons: Array<MeetingVideoPerson> = personsResponse.data

    const orderDataResponse = await http.get(`meeting/meetingreadonly/${meetingId}/mood_predicted_events_per_person/`)
    const orderData: Record<number, number> = orderDataResponse.data

    function comparePersons (p1: MeetingVideoPerson, p2: MeetingVideoPerson): number {
      let p1OrderValue = 0
      if (Object.prototype.hasOwnProperty.call(orderData, p1.id)) {
        p1OrderValue = orderData[p1.id]
      }

      let p2OrderValue = 0
      if (Object.prototype.hasOwnProperty.call(orderData, p2.id)) {
        p2OrderValue = orderData[p2.id]
      }

      if (p1OrderValue < p2OrderValue) {
        return -1
      }

      if (p1OrderValue > p2OrderValue) {
        return 1
      }

      return 0
    }

    persons.sort(comparePersons)
    // Persons with high number of events are now at the end of the list
    // therefore the list is reversed so that they end up being on top.
    persons.reverse()

    return persons
  },
  async submitIdentificationDataForMeetingId (
    context: ActionContext<State, State>, payload: Record<string, number | RequestIdentificationData>
  ): Promise<void> {
    await http.post(`meeting/meeting/${payload.meetingId}/identify_video_persons/`, payload.submitData)
  },
  async getProfileOfUser (context: ActionContext<State, State>): Promise<Profile | null> {
    const response = await http.get('meeting/profile/')
    const profile = response.data.length > 0 ? response.data[0] : null
    context.commit('setUserProfile', profile)
    return profile
  },
  async updateProfileForUser (context: ActionContext<State, State>, profile: Profile | null): Promise<Profile> {
    let response
    if (!profile) {
      response = await http.post('meeting/profile/', JSON.stringify({ has_seen_welcome_message: true }))
    } else {
      response = await http.patch(`meeting/profile/${profile.id}/`, JSON.stringify({ has_seen_welcome_message: true }))
    }
    context.commit('setUserProfile', response.data)
    return response.data
  },
  async getTourObjects (context: ActionContext<State, State>): Promise<Array<TourObject>> {
    const knownTourIds: Array<string> = []
    for (const key in getOnBoardingTours()) {
      knownTourIds.push(key)
    }
    const response = await http.get('meeting/tour/')
    const responseTours = response.data as Array<TourObject>

    const validTours: Array<TourObject> = []
    for (const tourObj of responseTours) {
      // 'meetingPlayer' tour was renamed to 'meetingPlayerSlide'
      if (tourObj.tour_id === 'meetingPlayer') {
        await http.patch(`meeting/tour/${tourObj.id}/`, JSON.stringify({ tour_id: 'meetingPlayerSlide' }))
        tourObj.tour_id = 'meetingPlayerSlide'
      }
      // clean up tours that were removed from object 'onBoardingTours' but may still exist on db
      if (knownTourIds.includes(tourObj.tour_id)) {
        validTours.push(tourObj)
      } else {
        await http.delete(`meeting/tour/${tourObj.id}/`)
      }
    }
    context.commit('setTourObjects', validTours)
    return validTours
  },
  async createTourObject (
    context: ActionContext<State, State>, data: TourObjectData): Promise<Record<string, TourObject>> {
    if (data.tour_id in context.state.tourObjects) {
      return context.state.tourObjects
    }
    const response = await http.post('meeting/tour/', JSON.stringify(data))
    context.commit('updateTourObject', { tourKey: response.data.tour_id, tourObject: response.data })
    return response.data
  },
  async getLatestWorkIncapacityItemState (context: ActionContext<State, State>, meetingItem: MeetingItem): Promise<WorkIncapacityItemData | null> {
    const params = {
      item: meetingItem.id,
      type: EventType.WorkIncapacityItemFinished,
      latest_type_item_combination: 'true'
    }
    const response = await http.get('meeting/event/', { params: params })

    if (response.data.length === 0) {
      return null
    }

    const result = response.data[0].extra_data

    // Extra info selected_form was added later and to avoid handling special cases
    // in the actual Vue.js components it is ensured that the action always returns
    // an object which completely resembles the WorkIncapacityItemData shape.
    if (!Object.prototype.hasOwnProperty.call(result, 'selected_form')) {
      result.selected_form = 0
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'performance_dynamic')) {
      result.performance_dynamic = 0
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'years_until_pension')) {
      result.years_until_pension = 10
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'prognosis_time')) {
      result.prognosis_time = 0
    }

    // Other stuff which was added afterwards.
    if (!Object.prototype.hasOwnProperty.call(result, 'birthday')) {
      result.birthday = null
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'gender')) {
      result.gender = ''
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'desired_pension_age')) {
      result.desired_pension_age = 67
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'daily_sickness_allowance')) {
      result.daily_sickness_allowance = false
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'event_increase')) {
      result.event_increase = false
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'unemployability')) {
      result.unemployability = false
    }

    if (!Object.prototype.hasOwnProperty.call(result, 'benefit_dynamic_decision')) {
      result.benefit_dynamic_decision = false
    }

    return result
  },
  async getAvailableConsultationModules (context: ActionContext<State, State>): Promise<Array<ConsultationModule>> {
    const response = await http.get('meeting/meetingitem/available_consultation_modules/')
    return response.data
  },
  async getWorkIncapacityItemsForCustomer (context: ActionContext<State, State>, customerId: number): Promise<Array<MeetingItem>> {
    const params = {
      meeting__meeting__customer: customerId.toString(),
      group_key: 'work_incapacity_item'
    }
    const response = await http.get('meeting/meetingitem/', { params: params })
    return ensureValidMeetingItems(response.data)
  },
  async createMoodEventForMeetingId (context: ActionContext<State, State>, payload: { meetingId: number, moodEventData: MoodEventData }): Promise<void> {
    await http.post(`meeting/meeting/${payload.meetingId}/create_mood_event/`, payload.moodEventData)
  },
  async getProfile (context: ActionContext<State, State>): Promise<ProfileUser> {
    const response = await http.get('auth/users/me/')
    return response.data
  },
  async getMeetingFormsForWiTool (context: ActionContext<State, State>, meetingId: number): Promise<Array<MeetingForm>> {
    const params: {
      meeting: string,
      // eslint-disable-next-line camelcase
      special_purpose: string
    } = {
      meeting: meetingId.toString(),
      special_purpose: MeetingFormFilterSpecialPurpose.WiTool
    }

    const response = await http.get('meeting/meetingform/', { params: params })
    return response.data
  },
  async getInitialWorkIncapacityItemState (context: ActionContext<State, State>, meetingFormId: number): Promise<WorkIncapacityItemData> {
    const response = await http.get(
      `meeting/meetingform/${meetingFormId}/get_initial_event_extra_data/${EventType.WorkIncapacityItemFinished}/`
    )
    return response.data
  },
  async getCustomReportTemplates (context: ActionContext<State, State>): Promise<Array<CustomReportTemplate>> {
    const response = await http.get('/meeting/customreporttemplate/')
    return response.data
  },
  async getInitialReportData (context: ActionContext<State, State>, payload: {reportId: number, templateId: number}): Promise<InitialReportData> {
    const response = await http.get(
      `meeting/report/${payload.reportId}/initial_report/${payload.templateId}/`
    )
    return response.data
  },
  async getAvailableUsersOfWorkspace (context: ActionContext<State, State>, workspaceId: number): Promise<User> {
    const response = await http.get(`meeting/workspace/${workspaceId}/get_all_available_workspace_users/`)
    return response.data
  },
  async aggregatedAnalysisData (
    context: ActionContext<State, State>,
    users: Array<number>
  ): Promise<AggregatedEmotionAnalysisRestData> {
    let params = {}
    if (users.length > 0) {
      params = {
        users: users
      }
    }
    const response = await http.get('meeting/aggregated_emotion_statistics/', { params: params })
    return response.data
  },
  async perContentAggregatedAnalysisData (
    context: ActionContext<State, State>,
    payload: PerContentAggregatedEmotionAnalysisRestRequestParams
  ): Promise<AggregatedEmotionAnalysisRestData> {
    const response = await http.post('meeting/per_content_aggregated_emotion_statistics_view/', payload)
    return response.data
  },
  async baseEmotionData (
    context: ActionContext<State, State>, payload: { meetingId: number, emotion: string }
  ): Promise<MeetingEmotionAnalysisData> {
    const params = {
      emotion: payload.emotion
    }
    const response = await http.get(`meeting/meetingreadonly/${payload.meetingId}/get_base_emotion/`, { params: params })
    return response.data
  }
}
