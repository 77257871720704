<template>
  <router-view/>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppContent'
})
</script>

<style scoped>

</style>
