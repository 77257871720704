export const MeetingItemsListProps = {
  getObjectAction: 'getMeetingById',
  listLinkPushPath: '/meetings',
  listLinkTitleKey: 'base.meetings',
  objectNotFoundMessageKey: 'messages.meeting_not_found'
}

export const MeetingFormsListProps = {
  getObjectAction: 'getMeetingById',
  listLinkPushPath: '/meetings',
  listLinkTitleKey: 'base.meetings',
  objectNotFoundMessageKey: 'messages.meeting_not_found',
  isConcreteMeeting: true,
  formCreatedMessageKey: 'messages.meeting_form_created',
  addFormAction: 'addFormToMeeting',
  titleKey: 'meta.pageTitles.meeting_forms_list',
  singleFormRouterLinkTo: 'MeetingFormView'
}

export const MeetingFormViewProps = {
  getObjectAction: 'getMeetingById',
  listLinkPushPath: '/meetings',
  objectNotFoundMessageKey: 'messages.meeting_not_found',
  isConcreteMeeting: true,
  titleKey: 'meta.pageTitles.meeting_form_view',
  backRouterLinkTo: 'MeetingFormsList',
  backRouterLinkTitle: 'navigate.back_to_meeting_forms_overview'
}

export const MeetingFormPrintViewProps = {
  getObjectAction: 'getMeetingById',
  listLinkPushPath: '/meetings',
  objectNotFoundMessageKey: 'messages.meeting_not_found',
  singleFormRouterLinkTo: 'MeetingFormView',
  singleFormRouterLinkTitle: 'meta.pageTitles.meeting_form_view'
}

export const MeetingTemplateItemsListProps = {
  getObjectAction: 'getMeetingTemplateById',
  listLinkPushPath: '/meeting_templates',
  listLinkTitleKey: 'base.meeting_templates',
  objectNotFoundMessageKey: 'messages.meeting_template_not_found'
}

export const MeetingTemplateFormsListProps = {
  getObjectAction: 'getMeetingTemplateById',
  listLinkPushPath: '/meeting_templates',
  listLinkTitleKey: 'base.meeting_templates',
  objectNotFoundMessageKey: 'messages.meeting_template_not_found',
  isConcreteMeeting: false,
  formCreatedMessageKey: 'messages.meeting_template_form_created',
  addFormAction: 'addFormToMeetingTemplate',
  titleKey: 'meta.pageTitles.meeting_template_forms_list',
  singleFormRouterLinkTo: 'MeetingTemplateFormView'
}

export const MeetingTemplateFormViewProps = {
  getObjectAction: 'getMeetingTemplateById',
  listLinkPushPath: '/meeting_templates',
  objectNotFoundMessageKey: 'messages.meeting_template_not_found',
  isConcreteMeeting: false,
  titleKey: 'meta.pageTitles.meeting_template_form_view',
  backRouterLinkTo: 'MeetingTemplateFormsList',
  backRouterLinkTitle: 'navigate.back_to_template_forms_overview'
}

export const MeetingTemplateFormPrintViewProps = {
  getObjectAction: 'getMeetingTemplateById',
  listLinkPushPath: '/meeting_templates',
  objectNotFoundMessageKey: 'messages.meeting_template_not_found',
  singleFormRouterLinkTo: 'MeetingTemplateFormView',
  singleFormRouterLinkTitle: 'meta.pageTitles.meeting_template_form_view'
}
