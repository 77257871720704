import router from '@/router'
import { store } from '@/store'
import { AlertType, ConsultantColourSchema, CustomerColourSchema, Filter, FilterGroupData } from '@/types/internal'
import {
  Customer,
  MeetingVideoPerson,
  MeetingVideoPersonRoles,
  WorkIncapacityItemData,
  WorkIncapacityScenario,
  Workspace
} from '@/types/descript_meeting_rest'
import http from '@/http-common'
import { Store } from 'vuex'
import { State } from '@/store/state'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { i18n } from '@/i18n'
import { axiosConfiguration } from '@/config/config'

export function isAuthenticated (): boolean {
  return localStorage.getItem('authToken') !== null
}

/**
 * General Error helper. This function will handle basic error Server responses like being not authorized or 500.
 * For non basic errors a callback is invoked when defined. This way error handling can happen in the components a
 * request is made or store actions are dispatched.
 * @example
 * store.dispatch('...').then(undefined, // success handling is optional
 *   errorHelper((error) => {
 *       // error handling here
 *    }
 *  ))
 * http.post('...').then(
          () => {
            // success
          }, errorHelper((error) => {
            // error handling of errors which weren't caught by the helper
          }
          )).catch(
          (response) => {
            // catching of js-errors
          }
        )
 */
export function errorHelper (callback?: (error: any, nonFieldErrorsHandled?: boolean) => void, statusCodesToPassThrough?: Array<number>) {
  return (error: any) => {
    if (error.message === 'Network Error') {
      store.commit('addImmediateAlert', {
        msg: 'error.network_error',
        type: AlertType.Danger
      })
      return
    }

    if (error.response && !statusCodesToPassThrough?.includes(error.response.status)) {
      if (error.response.status === 401) {
        clearAuthenticationInformation()
        router.push('/auth')
        store.commit('addPendingAlert', {
          msg: 'error.not_authenticated',
          type: AlertType.Danger
        })
        return
      } else if (error.response.status === 403) {
        if (error.response.data.detail.startsWith('CSRF Failed')) {
          if (callback) {
            // if we trigger a CSRF error and have and error handler we can return here,
            // as we will handle a logout there
            callback(error)
            return
          }
          store.commit('addImmediateAlert', {
            msg: 'error.csrf_failed',
            type: AlertType.Danger,
            autoHide: false
          })
        } else {
          store.commit('addPendingAlert', {
            msg: 'error.permission_denied',
            type: AlertType.Danger
          })
        }
        return
      }
      if (error.response.status === 500) {
        store.commit('addImmediateAlert', {
          msg: 'error.server_error',
          type: AlertType.Danger
        })
        return
      }
    }

    let nonFieldErrorsHandled = false

    if (error.response && error.response.data) {
      if (typeof error.response.data === 'object' && 'non_field_errors' in error.response.data) {
        for (const nonFieldError of error.response.data.non_field_errors) {
          store.commit('addImmediateAlert', {
            msg: nonFieldError.toString(),
            type: AlertType.Danger
          })

          nonFieldErrorsHandled = true
        }
      }
    }

    // This is no ordinary error. Handle in origin, when callback is present.
    if (callback) {
      callback(error, nonFieldErrorsHandled)
    } else if (!nonFieldErrorsHandled) {
      if (error.message) {
        store.commit('addImmediateAlert', { msg: error.message, type: AlertType.Danger })
      } else {
        store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
      }
      captureException(error)
    }
  }
}

export function convertToTimezone (date: Date, timeZone: string): Date {
  // Based on https://stackoverflow.com/a/54127122 and it may fail on
  // older browsers which do not support parameters for toLocaleString
  // method. Unfortunately there is no available standard library
  // method to do the job directly and therefore this workaround by
  // utilizing toLocaleString is used.
  return new Date(date.toLocaleString('en-US', { timeZone }))
}

export function datetimeLocalInputValue (date: Date): string {
  const year = date.getFullYear().toString().padStart(4, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hour}:${minute}`
}

/**
 * Returns a date string with a language sensitive
 * representation of the date
 * @param dateString
 * @param activeLocale
 */
export function getDate (dateString: string, activeLocale: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  return date.toLocaleDateString(activeLocale, options)
}

/**
 * Takes a formatted datestring and returns the actual weekday
 * @param dateString
 * @param activeLocale
 */
export function getDayOfWeek (dateString: string, activeLocale: string): string {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const wochentag = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

  const date = new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'))
  return (activeLocale === 'de') ? wochentag[date.getDay()] : weekday[date.getDay()]
}

/**
 * Takes a formatted datestring and checks if it is todays date
 * @param dateString
 * @param activeLocale
 * @returns
 */
export function checkCurrentDate (dateString: string, activeLocale: string): boolean {
  const todaysDate = new Date().toLocaleDateString(activeLocale)
  const date = new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1')).toLocaleDateString(activeLocale)
  return todaysDate === date
}

/**
 * Takes a formatted datestring and returns only days and month
 * @param dateString
 * @param activeLocale
 * @returns
 */
export function returnDateWithoutYear (dateString: string, activeLocale: string): string {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit'
  }
  return new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1')).toLocaleDateString(activeLocale, options)
}

/**
 * Returns a time string with a language sensitive
 * representation of the time
 * @param dateString
 * @param activeLocale
 */
export function getTime (dateString: string, activeLocale: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  }

  return date.toLocaleTimeString(activeLocale, options)
}

/**
 * Function to set the information defined by the workspace. The workspace information is saved in the localStorage
 * instead of the vuex-store to keep it in place even if the app is reloaded into the cache.
 * This function also dispatches an Event on which we can listen to update the styling or probably other interactions.
 * @param workspace
 */
export function setWorkspaceInformation (workspace: Workspace): void {
  localStorage.setItem('workspaceInformation', JSON.stringify(workspace))
  window.dispatchEvent(
    new CustomEvent('workspaceInformationChanged')
  )
}

/**
 * Function to reset the information defined by the workspace in the localStorage.
 * This function also dispatches an Event on which we
 * can listen to update the styling or probably other interactions
 */
export function clearWorkspaceInformation (): void {
  localStorage.removeItem('workspaceInformation')
  window.dispatchEvent(
    new CustomEvent('workspaceInformationChanged')
  )
}

/**
 * Function to reset the authentication information in the localStorage.
 * Currently theming variables are also determined via the user by the
 * connected workspace, therefore the reset of theming data is also triggered.
 */
export function clearAuthenticationInformation (): void {
  clearWorkspaceInformation()
  localStorage.removeItem('authToken')
  localStorage.removeItem('username')
}

/**
 * Returns the customer name
 * @param customer
 */
export function getCustomerName (customer: Customer): string {
  if (customer.first_name && customer.last_name) {
    return `${customer.first_name} ${customer.last_name}`
  }
  return customer.last_name
}

/**
 * Returns the first char of the customer name.
 * @param customer
 */
export function getCustomerNameInitials (customer: Customer): string {
  return getPersonInitials(getCustomerName(customer))
}

export function getPersonInitials (name: string): string {
  let initials = ''
  for (const subString of name.split(' ')) {
    initials += subString.charAt(0)
  }
  return initials
}

/**
 * Returns the address of a customer as Google Maps link.
 * @param customer
 */
export function getCustomerGoogleMapsAddressLink (customer: Customer): string {
  let link = 'https://www.google.com/maps/search/?api=1&query='
  link += encodeURIComponent(customer.address) + encodeURIComponent(',') + '+' + encodeURIComponent(customer.postal_code) + '+' + encodeURIComponent(customer.city)
  return link
}

export function compareStrings (s1: string, s2: string): number {
  const s1LowerCased = s1.toLowerCase()
  const s2LowerCased = s2.toLowerCase()

  return s1LowerCased.localeCompare(s2LowerCased, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}

export function compareCustomers (c1: Customer, c2: Customer): number {
  const c1LowerCased = `${c1.last_name} ${c1.first_name} ${c1.company}`.toLowerCase()
  const c2LowerCased = `${c2.last_name} ${c2.first_name} ${c2.company}`.toLowerCase()

  return c1LowerCased.localeCompare(c2LowerCased, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}

export function compareFilters (f1: Filter, f2: Filter): number {
  const f1LowerCased = f1.label ? f1.label.toLowerCase() : f1.value.toLowerCase()
  const f2LowerCased = f2.label ? f2.label.toLowerCase() : f2.value.toLowerCase()

  return f1LowerCased.localeCompare(f2LowerCased, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}

export async function signOut (store: Store<State>): Promise<void> {
  const token = localStorage.getItem('authToken')
  if (token !== null) {
    await http.post('auth/token/logout/').then(
      () => {
        clearAuthenticationInformation()
      }, errorHelper((error) => {
        store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
      }
      )).catch(
      (response) => {
        store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
      }
    )
  }
}

export function workIncapacityItemDataCalculations (data: WorkIncapacityItemData): WorkIncapacityItemData {
  // step 1 calculations
  data.sum_expenses = data.reside_expenses
  data.sum_expenses = data.sum_expenses + data.living_expenses
  data.sum_expenses = data.sum_expenses + data.mobility_expenses
  data.sum_expenses = data.sum_expenses + data.insurance_expenses
  data.sum_expenses = data.sum_expenses + data.other_expenses
  data.sum_income = data.net_income
  data.sum_income = data.sum_income + data.pension_income
  data.sum_income = data.sum_income + data.other_income

  // step 2 calculations
  if (data.more_than_60_month_pension_paid) {
    if (data.scenario === WorkIncapacityScenario.ZeroToThreeHours) {
      data.disability_pension = data.gross_income * 0.34
    }
    if (data.scenario === WorkIncapacityScenario.ThreeToSixHours) {
      data.disability_pension = data.gross_income * 0.17
    }
  } else {
    data.disability_pension = 0
  }
  data.disability_pension = Math.round(data.disability_pension)
  data.supply_gap_is = Math.max(data.sum_expenses - data.disability_pension, 0)

  // step 3 calculations
  data.desired_wi_pension = data.desired_factor * data.net_income / 100
  data.desired_supply_gap = data.sum_expenses - data.desired_wi_pension

  data.desired_wi_pension = Math.round(data.desired_wi_pension)
  data.desired_supply_gap = Math.max(Math.round(data.desired_supply_gap), 0)
  return data
}

export function captureException (event: ErrorEvent | PromiseRejectionEvent | unknown): void {
  console.log(event)
  Sentry.setExtra('username', localStorage.getItem('username'))
  Sentry.captureException(event)
}

export function captureExceptionAsInfo (event: ErrorEvent | PromiseRejectionEvent | unknown): void {
  Sentry.setExtra('username', localStorage.getItem('username'))
  Sentry.captureException(event)
  Sentry.withScope(function (scope) {
    scope.setLevel(Severity.Info)
    Sentry.captureException(event)
  })
}

export function getPrettyTime (seconds: number): string {
  const secondsRounded = Math.round(seconds)
  const prettySeconds = secondsRounded % 60
  const prettySecondsString = prettySeconds > 9 ? `${prettySeconds}` : `0${prettySeconds}`
  return i18n.global.t('base.pretty_time', { time: `${Math.floor(secondsRounded / 60)}:${prettySecondsString}` })
}

export function getLocalizedNumber (value: number | null | undefined, activeLocale: string, maximumFractionDigits: number | undefined): string {
  if (value === undefined) {
    return '-'
  }

  if (value === null) {
    return '-'
  }

  if (maximumFractionDigits === undefined) {
    maximumFractionDigits = 1
  }

  return value.toLocaleString(activeLocale, { maximumFractionDigits })
}

export function getLocalizedDateTime (value: Date | null | undefined, activeLocale: string): string {
  if (value === undefined) {
    return '-'
  }

  if (value === null) {
    return '-'
  }

  return value.toLocaleString(activeLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })
}

export function getPersonName (personId: number, personList: Array<MeetingVideoPerson>): string {
  let name = ''
  for (const person of personList) {
    if (person.id === personId) {
      name = person.name
      break
    }
  }
  return name
}

export function getColorClass (mood: number | undefined | null): string {
  if (mood === undefined) {
    return ''
  }

  if (mood === null) {
    return ''
  }

  if (mood >= 70) {
    return 'success'
  }

  if (mood < 30) {
    return 'danger'
  }

  return ''
}

export function isActiveFilter (candidate: Filter, activeFilters: Array<Filter>) {
  for (const activeFilter of activeFilters) {
    if (candidate.key === activeFilter.key && candidate.value === activeFilter.value) {
      return true
    }
  }

  return false
}

export function availableFilterByGroup (filters: Array<Filter>, activeFilters: Array<Filter>): Array<FilterGroupData> {
  // filters is a list of available filters which must be sorted
  // in the sense that filters are grouped by group: So first
  // come all the elements for group a then come all the elements
  // for group b and so forth.

  const byGroup: Array<FilterGroupData> = []

  let lastGroup = ''
  for (const filter of filters) {
    if (filter.group !== lastGroup) {
      byGroup.push(
        {
          group: filter.group,
          verbose: i18n.global.t(`filter_key_verbose.${filter.group}`),
          verboseNotActive: i18n.global.t(`filter_key_verbose_not_active.${filter.group}`),
          filters: [],
          activeFilter: null
        })
    }

    byGroup[byGroup.length - 1].filters.push(filter)

    if (isActiveFilter(filter, activeFilters)) {
      byGroup[byGroup.length - 1].activeFilter = filter
    }

    lastGroup = filter.group
  }

  return byGroup
}

export function isValidNext (next: string): boolean {
  return next.startsWith(axiosConfiguration.baseURL) || next.startsWith('/')
}

export function getRoleBasedColourSchema (videoPersonList: Array<MeetingVideoPerson>): Array<string> {
  let customerIndex = 0
  let consultantIndex = 0
  const meetingVideoPersonColourList: Array<string> = []

  videoPersonList.forEach((person: MeetingVideoPerson) => {
    if (person.role === MeetingVideoPersonRoles.Customer) {
      meetingVideoPersonColourList.push(CustomerColourSchema[customerIndex])
      customerIndex = (customerIndex + 1) % (CustomerColourSchema.length)
    } else if (person.role === MeetingVideoPersonRoles.Consultant) {
      meetingVideoPersonColourList.push(ConsultantColourSchema[consultantIndex])
      consultantIndex = (consultantIndex + 1) % (ConsultantColourSchema.length)
    }
  })
  return meetingVideoPersonColourList
}
