import axios from 'axios'
import { axiosConfiguration } from '@/config/config'
import { version } from '../package.json'

const axiosInstance = axios.create(axiosConfiguration)

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('authToken')

  if (token && config.headers) {
    config.headers.Authorization = 'Token ' + token
  }

  const language = localStorage.getItem('currentSelectedLanguage')

  if (language && config.headers) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.common['Accept-Language'] = language
  }
  return config
})

axiosInstance.interceptors.response.use(function checkResponse (successResponse) {
  if (successResponse.headers &&
      successResponse.headers['frontend-server-version'] &&
      successResponse.headers['frontend-server-version'] !== version) {
    window.dispatchEvent(
      new CustomEvent('vueVersionUpdated')
    )
  }
  return successResponse
}, undefined)

export default axiosInstance
