import { createStore } from 'vuex'
import { getEmptyState } from '@/store/state'
import { mutations } from '@/store/mutations'
import { actions } from '@/store/actions'

export const store = createStore({
  state: getEmptyState(),
  mutations: mutations,
  actions: actions
})
