import {
  Meeting,
  Topic,
  Report,
  SlideDeck,
  MeetingForm,
  FormSchema,
  Customer,
  MeetingTemplate,
  Profile,
  TourObject
} from '@/types/descript_meeting_rest'
import {
  Message,
  Filter,
  InstancesWithSearchAndFilterState
} from '@/types/internal'

/**
 * Current State of the Vue App
 */
export interface State {
  immediateAlerts: Array<Message>,
  pendingAlerts: Array<Message>,
  availableMeetingsFilter: Array<Filter>,
  meetingsWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<Meeting>>,
  nextMeeting: Meeting | null,
  topics: Array<Topic>,
  reports: Array<Report>,
  availableSlideDeckFilter: Array<Filter>,
  availableFormSchemaFilter: Array<Filter>,
  slideDecksWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<SlideDeck>>,
  meetingFormsWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<MeetingForm>>,
  formSchemasWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<FormSchema>>,
  customersWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<Customer>>,
  availableCustomersFilter: Array<Filter>,
  availableMeetingTemplatesFilter: Array<Filter>,
  meetingTemplatesWithSearchAndFilterState: Record<string, InstancesWithSearchAndFilterState<MeetingTemplate>>,
  profile: Profile | null,
  tourObjects: Record<string, TourObject>
}

export function getEmptyState (): State {
  return {
    immediateAlerts: [],
    pendingAlerts: [],
    availableMeetingsFilter: [],
    meetingsWithSearchAndFilterState: {},
    nextMeeting: null,
    topics: [],
    reports: [],
    availableSlideDeckFilter: [],
    availableFormSchemaFilter: [],
    slideDecksWithSearchAndFilterState: {},
    meetingFormsWithSearchAndFilterState: {},
    formSchemasWithSearchAndFilterState: {},
    customersWithSearchAndFilterState: {},
    availableCustomersFilter: [],
    availableMeetingTemplatesFilter: [],
    meetingTemplatesWithSearchAndFilterState: {},
    profile: null,
    tourObjects: {}
  }
}
