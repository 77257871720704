/* eslint camelcase: "off" */
import { FreehandNoteData } from '@/types/freehand_note'
import { convertToTimezone, datetimeLocalInputValue } from '@/utils'
import { url } from '@/config/config'

export interface SlideFromRest {
  id: number,
  content: string,
  thumbnail: string,
  slide_deck_version: number,
  slide_number: number,
  group_key: string
}

export class Slide {
  id: number
  content: string
  thumbnail: string
  slide_deck_version: number
  slide_number: number
  group_key: string

  constructor (restSlide?: SlideFromRest) {
    if (!restSlide) {
      restSlide = {
        id: 0,
        content: '',
        thumbnail: '',
        slide_deck_version: 0,
        slide_number: 0,
        group_key: ''
      }
    }

    this.content = restSlide.content
    this.thumbnail = restSlide.thumbnail
    this.slide_deck_version = restSlide.slide_deck_version
    this.slide_number = restSlide.slide_number
    this.id = restSlide.id
    this.group_key = restSlide.group_key
  }

  sorlThumbnail (width: number, height: number): string {
    return `${url}meeting/slide/${this.id}/sorl_thumbnail_serving/${width}/${height}/`
  }

  sorlThumbnailSrcSet (width: number, height: number): string {
    const thumbnailUrl = `${url}meeting/slide/${this.id}/sorl_thumbnail_serving/`

    return `${thumbnailUrl}${width}/${height}/ 1x, ${thumbnailUrl}${width * 2}/${height * 2}/ 2x`
  }
}

export interface Topic {
  id: number,
  name: string
}

// This must always reflect the constants like defined in descript_meeting
export enum MaritalState {
  None = 0,
  Single = 1,
  Married = 2,
  Widowed = 3,
  Divorced = 4,
  Separated = 5,
  DomesticRelationship = 6
}

// This must always reflect CompanyFilter.empty_value in descript_meeting
export const withoutCompanyFilterMagicValue = 'WITHOUTCOMPANY'

export interface Customer {
  id: number,
  first_name: string,
  last_name: string,
  company: string,
  address: string,
  postal_code: string,
  city: string,
  email: string,
  telephone_number: string,
  birthday: string | null,
  marital_status: MaritalState,
  occupation: string,
  child_count: number | null,
  yearly_income: number | null,
  notes: string
}

export function getPlaceholderCustomer (): Customer {
  return {
    id: 0,
    first_name: '',
    last_name: '',
    company: '',
    address: '',
    postal_code: '',
    city: '',
    email: '',
    telephone_number: '',
    birthday: null,
    marital_status: MaritalState.None,
    occupation: '',
    child_count: null,
    yearly_income: null,
    notes: ''
  }
}

// This must always reflect the constants like defined in descript_meeting
export enum MeetingVideoAnalysisState {
  NoUpload = 'no_upload',
  InProgress = 'in_progress',
  Identification = 'identification',
  Completed = 'completed'
}

export interface MeetingFromRest {
  customer: number | null,
  customer_serialized: Customer | null,
  end: string,
  meeting_item_content_types: Array<string>,
  id: number,
  location: string,
  report_has_content: boolean,
  start: string,
  title: string,
  topics: Array<number>,
  with_emotion_analysis: boolean,
  video_analysis_state: MeetingVideoAnalysisState
  true_start_time: string
  third_party_service_id: string
  third_party_service_link: string
}

abstract class MeetingBase {
  abstract meeting_item_content_types: Array<string>
  abstract id: number
  abstract location: string
  abstract title: string
  abstract topics: Array<number>

  meetingHasItemsToExecute (): boolean {
    return this.meeting_item_content_types.includes('slide') ||
      this.meeting_item_content_types.includes('workincapacityitem')
  }
}

export interface MeetingDetailsFormData {
  customer: number | null,
  end: string,
  location: string,
  meeting_template?: number,
  start: string,
  title: string,
  topics: Array<number>,
  with_emotion_analysis: string,
  video_analysis_state: MeetingVideoAnalysisState
}

export enum ThirdPartyServices {
  Zoom = 'zoom'
}

export class Meeting extends MeetingBase {
  customer: number | null
  customer_serialized: Customer | null
  end: string
  meeting_item_content_types: Array<string>
  id: number
  location: string
  report_has_content: boolean
  start: string
  title: string
  topics: Array<number>
  with_emotion_analysis: boolean
  video_analysis_state: MeetingVideoAnalysisState
  true_start_time: string
  third_party_service_id: string
  third_party_service_link: string

  constructor (restMeeting?: MeetingFromRest) {
    /**
     * restMeeting is optional to allow the usage of new Meeting()
     * when just a placeholder meeting is needed.
     */

    super()

    if (!restMeeting) {
      restMeeting = {
        id: 0,
        meeting_item_content_types: [],
        report_has_content: false,
        title: '',
        location: '',
        start: '',
        end: '',
        customer: null,
        customer_serialized: null,
        topics: [],
        with_emotion_analysis: false,
        video_analysis_state: MeetingVideoAnalysisState.NoUpload,
        true_start_time: '',
        third_party_service_id: '',
        third_party_service_link: ''
      }
    }

    this.customer = restMeeting.customer
    this.customer_serialized = restMeeting.customer_serialized
    this.end = restMeeting.end
    this.meeting_item_content_types = restMeeting.meeting_item_content_types
    this.id = restMeeting.id
    this.location = restMeeting.location
    this.report_has_content = restMeeting.report_has_content
    this.start = restMeeting.start
    this.title = restMeeting.title
    this.topics = restMeeting.topics
    this.with_emotion_analysis = restMeeting.with_emotion_analysis
    this.video_analysis_state = restMeeting.video_analysis_state
    this.true_start_time = restMeeting.true_start_time
    this.third_party_service_id = restMeeting.third_party_service_id
    this.third_party_service_link = restMeeting.third_party_service_link
  }

  getFormData (): MeetingDetailsFormData {
    let datetimeLocalInputCompatibleStart: string
    let datetimeLocalInputCompatibleEnd: string

    if (this.start === '' && this.end === '') {
      datetimeLocalInputCompatibleStart = ''
      datetimeLocalInputCompatibleEnd = ''
    } else {
      const startInServerTimeZone = new Date(this.start)
      const browserTimeZone = (new window.Intl.DateTimeFormat()).resolvedOptions().timeZone
      const startInBrowserTimeZone = convertToTimezone(startInServerTimeZone, browserTimeZone)
      const serverTimeZoneOffset = startInServerTimeZone.getTimezoneOffset()
      const browserTimeZoneOffset = startInBrowserTimeZone.getTimezoneOffset()

      if (serverTimeZoneOffset === browserTimeZoneOffset) {
        // We received an ISO formatted string with a timezone which is in the
        // same timezone the browser of the current user is in. We just need to
        // strip timezone info from via REST api retrieved strings.
        datetimeLocalInputCompatibleStart = this.start.substring(0, 16)
        datetimeLocalInputCompatibleEnd = this.end.substring(0, 16)
      } else {
        // The value from the server has a different timezone then the browser of
        // the current user. The datetime-local input needs an initial value which
        // represents the same point in time like meeting.start as received from
        // the server but in the timezone of the browser of the current user.
        // Example: Server sends start is 2021-05-02T06:00+02:00 because our server
        // timezone is Berlin. The Mataono user sits in Chicago. The timezone
        // offset is -05:00 then. We need an initial value for the datetime-local
        // which represents 2021-05-02T06:00+02:00 in -05:00 timezone. Correct result
        // is then 2021-05-01T23:00-05:00 and 2021-05-01T23:00 is the correct initial
        // value for the datetime local. To get to the correct result the Date objects
        // are converted to the browser's time zone and then we derive the correct
        // initial value for the datetime-local inputs from these converted Date
        // objects. Doing it so also handles gritty details like daylight saving times.

        const endInServerTimeZone = new Date(this.end)
        const endInBrowserTimeZone = convertToTimezone(endInServerTimeZone, browserTimeZone)

        datetimeLocalInputCompatibleStart = datetimeLocalInputValue(startInBrowserTimeZone)
        datetimeLocalInputCompatibleEnd = datetimeLocalInputValue(endInBrowserTimeZone)
      }
    }

    return {
      customer: this.customer,
      title: this.title,
      location: this.location,
      start: datetimeLocalInputCompatibleStart,
      end: datetimeLocalInputCompatibleEnd,
      topics: this.topics,
      with_emotion_analysis: this.with_emotion_analysis ? 'on' : '',
      video_analysis_state: this.video_analysis_state
    }
  }

  isFromThirdPartyService (): boolean {
    return !!this.third_party_service_id
  }

  isFromZoom (): boolean {
    return !!this.third_party_service_id && this.third_party_service_id.startsWith(ThirdPartyServices.Zoom)
  }
}

export enum MeetingViewSets {
  Meeting = 'meeting',
  MeetingReadOnly = 'meetingreadonly'
}

export interface SlideDeckFromRest {
  id: number,
  title: string,
  description: string,
  topics: Array<number>,
  is_deleted: boolean,
  first_slide_of_slide_deck: SlideFromRest
}

export interface SlideDeck {
  id: number,
  title: string,
  description: string,
  topics: Array<number>,
  is_deleted: boolean,
  first_slide_of_slide_deck: Slide
}

export function getPlaceholderSlideDeck (): SlideDeck {
  return {
    id: 0,
    title: '',
    description: '',
    topics: [] as Array<number>,
    is_deleted: false,
    first_slide_of_slide_deck: new Slide()
  }
}

export interface AddSlideDeckData {
  title: string,
  description: string,
  topics: Array<number>
}

export interface SlideDeckVersion {
  id: number,
  created: string,
  slide_deck: number,
  slide_count: number,
  version_number: number,
  presentation_file: string,
  change_log: string,
  author: string
}

export function getPlaceholderSlideDeckVersion (): SlideDeckVersion {
  return {
    id: 0,
    created: '',
    slide_deck: 0,
    slide_count: 0,
    version_number: 0,
    presentation_file: '',
    change_log: '',
    author: ''
  }
}

export interface AddSlideDeckVersionData {
  slide_deck: number,
  presentation_file: File,
  change_log: string
}

// This must always reflect CONTENT_MODELS like defined in descript_meeting
export enum ContentType {
  Slide = 'meeting.slide',
  ConsentItem = 'meeting.consentitem',
  WorkIncapacityItem = 'meeting.workincapacityitem',
  FinishMeetingMeetingItem = 'meeting.finishmeetingmeetingitem',
  MeetingForm = 'meeting.meetingform',
  // Additional content type because for aggregated analysis view the data
  // about multiple forms derived from the same form schema should be aggregated
  // and therefore this content type is used as well. But a form schema is
  // related to a meeting item.
  FormSchema = 'meeting.formschema'
}

// This must always reflect the constants like defined in descript_meeting
export enum LikeStatus {
  Undecided = 1,
  Liked = 2,
  Disliked = 3
}

export interface AddMeetingItemData {
  order: number,
  meeting: number,
  content_type: ContentType,
  content_object_id: number,
  like_status: LikeStatus,
  note: string
}

export interface ConsultationModule {
  id: number,
  content_type: ContentType
}

// This must always reflect the constants like defined in descript_meeting
export enum MeetingFormState {
  Created = 1,
  SentOut = 2,
  Editing = 3,
  Withdrawn = 4,
  Finished = 5
}

export enum MeetingFormErrorStates {
  formWithdrawn = 'FORM_WITHDRAWN',
  fillInExpired = 'FORM_FILL_IN_EXPIRED'
}

export interface FormSchema {
  id: number,
  workspace: number,
  title: string,
  description: string,
  topics: Array<number>,
  form_schema: Record<string, unknown>,
  form_ui_schema: Record<string, unknown>
}

export interface MeetingFormFromRest {
  id: number,
  meeting: number,
  title: string,
  form_schema: Record<string, unknown>,
  form_ui_schema: Record<string, unknown>,
  answers: Record<string, unknown>,
  valid_until: string,
  state: MeetingFormState,
  hash: string,
  meeting_start: string
}

interface AbstractMeetingForm {
  title: string,
  form_schema: Record<string, unknown>,
  form_ui_schema: Record<string, unknown>,
  answers: Record<string, unknown>,
  state: MeetingFormState,
  hash: string,
  valid_until: string,
}

export interface MeetingForm extends AbstractMeetingForm {
  id: number,
  meeting: number,
}

export function getPlaceholderMeetingForm (): MeetingForm {
  return {
    id: 0,
    meeting: 0,
    title: '',
    form_schema: {},
    form_ui_schema: {},
    answers: {},
    valid_until: '',
    state: MeetingFormState.Created,
    hash: ''
  }
}

export interface HashedMeetingForm extends AbstractMeetingForm {
  meeting_start: string
}

export function getPlaceholderHashedMeetingForm (): HashedMeetingForm {
  return {
    title: '',
    form_schema: {},
    form_ui_schema: {},
    answers: {},
    valid_until: '',
    state: MeetingFormState.Created,
    hash: '',
    meeting_start: ''
  }
}

export interface ConsultationModuleInstance {
  id: number
}

export interface Point{
  x: number,
  y: number
}

export interface MeetingItemLink {
  upperLeftCorner: Point,
  lowerRightCorner: Point
  targetMeetingItemId: number
}

export interface MeetingItemFromRest {
  id: number,
  meeting: number,
  order: number,
  content_type: ContentType,
  content_object_id: number,
  like_status: LikeStatus,
  note: string,
  free_hand_note_vector: string | null,
  free_hand_note_json: Record<string, unknown>,
  group_key: string,
  serialized_specific_meeting_item: Slide | ConsultationModuleInstance | MeetingForm,
  links: Array<MeetingItemLink>
}

export interface MeetingItem {
  id: number,
  meeting: number,
  order: number,
  content_type: ContentType,
  content_object_id: number,
  like_status: LikeStatus,
  note: string,
  free_hand_note_vector: string | null,
  free_hand_note_json: FreehandNoteData,
  group_key: string,
  serialized_specific_meeting_item: Slide | ConsultationModuleInstance | MeetingForm | null,
  links: Array<MeetingItemLink>
}

export function getPlaceholderMeetingItem (): MeetingItem {
  return {
    id: 0,
    meeting: 0,
    order: 0,
    content_type: ContentType.Slide,
    content_object_id: 0,
    like_status: LikeStatus.Undecided,
    note: '',
    free_hand_note_vector: '',
    free_hand_note_json: {
      canvasWidth: 0,
      pointGroups: []
    },
    group_key: '',
    serialized_specific_meeting_item: null,
    links: []
  }
}

// This must always reflect the constants like defined in descript_meeting
export enum EventType {
  MeetingStarted = 1,
  FlippedTo = 2,
  Liked = 3,
  LikeRemoved = 4,
  Disliked = 5,
  DislikeRemoved = 6,
  NoteAdded = 7,
  NoteEdited = 8,
  NoteDeleted = 9,
  MeetingEnded = 10,
  FreeHandNoteModeActivated = 11,
  FreeHandNoteSave = 12,
  FreeHandNoteReset = 13,
  FreeHandNoteDelete = 14,
  FreeHandNoteModeDeactivated = 15,
  MoodPredicted = 16,
  ConsentGiven = 17,
  ConsentRefused = 18,
  WorkIncapacityItemFinished = 19
}

export interface Event {
  id: number,
  meeting: number,
  created: string,
  type: EventType,
  item: number | null
}

export interface Report {
  id: number,
  meeting: number,
  content: string,
  used_template: number | null
}

export function getPlaceholderReport (): Report {
  return {
    id: 0,
    meeting: 0,
    content: '',
    used_template: null
  }
}

export interface MeetingEmotionAnalysisData {
  continuous_mood_graphs: Record<number, Array<[number, number | null]>>,
  flipped_to_annotations: Array<[number, number]>,
  average_moods: Record<number, number>,
  happy_times: Record<number, number>,
  sad_times: Record<number, number>,
  total_time: number,
  min_max_average_mood_item: Record<number, { min: number | null, max: number | null }>,
  correlations: Record<number, Record<number, number>>,
  standard_deviation: Record<number, number>,
  per_slide: {
    average_moods: Record<number, Array<[number, number]>>,
    standard_deviation: Record<number, Array<[number, number]>>,
    times: Record<number, Record<number, { positive: number, negative: number, total: number }>> // total is derived from meeting item time
  },
  combined: {
    average_mood: Record<number, Record<number, number>>,
    times: Record<number, Record<number, { positive: number, negative: number, total: number }>>, // derived?
    correlations: Record<number, Record<number, number>>
  },
  paired: {
    average_distances: Record<number, Record<number, number>>,
    distances_standard_deviation: Record<number, Record<number, number>>,
    distances_times: Record<number, Record<number, { times_far: number, times_close: number }>>,
    min_max_distance_item: Record<number, Record<number, { min: number | null, max: number | null }>>,
    average_distances_per_item: Record<number, Record<number, Record<number, number>>>,
    distance_standard_deviation_per_item: Record<number, Record<number, Record<number, number>>>,
    distance_min_max_per_item: Record<number, Record<number, Record<number, { min_distance: number, max_distance: number }>>>,
    distance_times_per_item: Record<number, Record<number, Record<number, { times_far: number, times_close: number }>>>
  }
}

export function getPlaceholderEmotionAnalysisData (): MeetingEmotionAnalysisData {
  return {
    continuous_mood_graphs: {},
    flipped_to_annotations: [],
    average_moods: {},
    happy_times: {},
    sad_times: {},
    total_time: 0,
    min_max_average_mood_item: {},
    correlations: {},
    standard_deviation: {},
    per_slide: {
      average_moods: {},
      standard_deviation: {},
      times: {}
    },
    combined: {
      average_mood: {},
      times: {},
      correlations: {}
    },
    paired: {
      average_distances: {},
      distances_standard_deviation: {},
      distances_times: {},
      min_max_distance_item: {},
      average_distances_per_item: {},
      distance_standard_deviation_per_item: {},
      distance_min_max_per_item: {},
      distance_times_per_item: {}
    }
  }
}

export interface ConsentItem {
  id: number
}

export interface Workspace {
  id: number,
  name: string,
  theme_colour_primary: string
  theme_colour_secondary: string
  theme_logo: string,
  theme_favicon: string,
}

export interface GenerateTestDataResult {
  success: boolean
}

export interface MeetingTemplateFromRest {
  id: number,
  meeting_item_content_types: Array<string>,
  title: string,
  topics: Array<number>,
  location: string,
  description: string,
  duration: number | null,
  slide_decks: Array<string>,
  forms: Array<string>
}

export interface MeetingTemplateDetailsFormData {
  title: string
  topics: Array<number>
  location: string
  description: string
  duration: number | null
}

export class MeetingTemplate extends MeetingBase {
  id: number
  meeting_item_content_types: Array<string>
  title: string
  topics: Array<number>
  location: string
  description: string
  duration: number | null
  slide_decks: Array<string>
  forms: Array<string>

  constructor (templateFromRest?: MeetingTemplateFromRest) {
    /**
     * templateFromRest is optional to allow the usage of new MeetingTemplate()
     * when just a placeholder meeting template is needed.
     */

    super()

    if (!templateFromRest) {
      templateFromRest = {
        id: 0,
        meeting_item_content_types: [],
        title: '',
        location: '',
        topics: [],
        description: '',
        duration: null,
        slide_decks: [],
        forms: []
      }
    }

    this.id = templateFromRest.id
    this.meeting_item_content_types = templateFromRest.meeting_item_content_types
    this.title = templateFromRest.title
    this.topics = templateFromRest.topics
    this.location = templateFromRest.location
    this.description = templateFromRest.description
    this.duration = templateFromRest.duration
    this.slide_decks = templateFromRest.slide_decks
    this.forms = templateFromRest.forms
  }

  getFormData (): MeetingTemplateDetailsFormData {
    return {
      title: this.title,
      topics: this.topics,
      location: this.location,
      description: this.description,
      duration: this.duration
    }
  }
}

export function isMeetingTemplate (obj: unknown): obj is MeetingTemplate {
  return Object.prototype.hasOwnProperty.call(obj, 'description') && (obj as MeetingTemplate).description !== undefined
}

export interface MeetingVideo {
  id: number,
  meeting: number,
  file: string,
  progress: number
}

// These must reflect the MEETING_VIDEO_PERSON_ROLE_* constants in descript_meeting.
export enum MeetingVideoPersonRoles {
  None,
  Customer,
  Consultant
}

export interface MeetingVideoPerson {
  id: number,
  meeting: number,
  name: string,
  role: MeetingVideoPersonRoles,
  face: string
}

export function getPlaceholderMeetingVideoPerson (): MeetingVideoPerson {
  return {
    id: 0,
    meeting: 0,
    name: '',
    role: MeetingVideoPersonRoles.None,
    face: ''
  }
}

// This must always reflect the constants like defined in descript_meeting
export enum ProfileIndustry {
  None = 0,
  Banking = 1,
  Insurance = 2,
  Retailing = 3,
  Consulting = 4,
  Legal = 5,
  Healthcare = 6,
  Other = 7
}
// This must always reflect the constants like defined in descript_meeting
export enum ProfileReferral {
  None = 0,
  ContactedByChris = 1,
  Website = 2,
  LinkedIn = 3,
  Instagram = 4,
  PersonalNetwork = 5,
  Other = 6
}

export enum CancellationReason {
  None,
  Expectations,
  Usability,
  Features,
  Technical,
  JustLooking
}

export interface Profile {
  id: number,
  industry: number,
  profession: string,
  referral: number,
  has_seen_welcome_message: boolean
}

export enum TourStates {
  finished = 1,
  cancelled = 2,
}

export interface TourObject {
  id: number,
  tour_id: string,
  tour_state: TourStates
}

// This must always reflect the constants like defined in descript_meeting
export enum WorkIncapacityScenario {
  ZeroToThreeHours,
  ThreeToSixHours
}

// This also what is saved in the extra_data field of the corresponding
// events which are created for the work incapacity items.
export interface WorkIncapacityItemData {
  // General Information
  customer_name: string,
  selected_form: number,
  // Expenses
  reside_expenses: number,
  living_expenses: number,
  mobility_expenses: number,
  insurance_expenses: number,
  other_expenses: number,
  sum_expenses: number,
  // Income
  gross_income: number,
  net_income: number,
  pension_income: number,
  other_income: number,
  sum_income: number,
  // WI-is-State
  more_than_60_month_pension_paid: boolean,
  scenario: WorkIncapacityScenario,
  disability_pension: number,
  supply_gap_is: number,
  // WI-goal-state
  desired_factor: number,
  desired_wi_pension: number,
  desired_supply_gap: number,
  // Inflation and dynamic
  expected_inflation_rate: number,
  desired_contribution_dynamic: number,
  performance_dynamic: number,
  years_until_pension: number,
  prognosis_time: number,
  // Stuff from selected form
  birthday: string | null,
  gender: string,
  desired_pension_age: number,
  daily_sickness_allowance: boolean,
  event_increase: boolean,
  unemployability: boolean,
  benefit_dynamic_decision: boolean,
}

export function getPlaceholderWorkIncapacityItemData (): WorkIncapacityItemData {
  return {
    customer_name: '',
    selected_form: 0,
    reside_expenses: 0,
    living_expenses: 0,
    mobility_expenses: 0,
    insurance_expenses: 0,
    other_expenses: 0,
    sum_expenses: 0,
    gross_income: 0,
    net_income: 0,
    pension_income: 0,
    other_income: 0,
    sum_income: 0,
    more_than_60_month_pension_paid: false,
    scenario: WorkIncapacityScenario.ThreeToSixHours,
    disability_pension: 0,
    supply_gap_is: 0,
    desired_factor: 0,
    desired_wi_pension: 0,
    desired_supply_gap: 0,
    expected_inflation_rate: 2,
    desired_contribution_dynamic: 0,
    performance_dynamic: 0,
    years_until_pension: 10,
    prognosis_time: 0,
    birthday: null,
    gender: '',
    desired_pension_age: 67,
    daily_sickness_allowance: false,
    event_increase: false,
    unemployability: false,
    benefit_dynamic_decision: false
  }
}

export interface EventWorkIncapacityItemFinished extends Event {
  type: EventType.WorkIncapacityItemFinished,
  item: number,
  extra_data: WorkIncapacityItemData
}

export interface MoodEventData {
  meeting_item: number,
  timestamp: string,
  name: string,
  meeting_video_person: number | null,
  faces: Array<string>,
  emotions: Array<[number, number, number, number, number, number, number]>
}

export interface User {
  id: number,
  username: string,
  email: string,
  first_name: string,
  last_name: string
}

export interface ProfileUser {
  id: number,
  username: string,
  email: string,
  first_name: string,
  last_name: string,
  user_permissions: Array<string>
}

export function getPlaceholderProfileUser (): ProfileUser {
  return {
    id: 0,
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    user_permissions: []
  }
}

// This must always reflect like it is defined in descript_meeting, look next to class
// meeting.rest.form_management.SpecialPurposeFilter
export enum MeetingFormFilterSpecialPurpose {
  WiTool = 'wi_tool'
}

export interface CustomReportTemplate {
  id: number,
  workspace: number,
  report_generator_class: string,
  template_to_render: string,
  template_name: string
}

export interface InitialReportData {
  initial: string
}

export type PerMeetingVideoPersonRole<T> = Partial<Record<MeetingVideoPersonRoles, T>>

export interface AggregatedEmotionAnalysisRestData {
  average_moods: PerMeetingVideoPersonRole<number>,
  happy_times: PerMeetingVideoPersonRole<number>,
  sad_times: PerMeetingVideoPersonRole<number>,
  standard_deviation: PerMeetingVideoPersonRole<number>
}

export function getPlaceholderAggregatedEmotionAnalysisRestData (): AggregatedEmotionAnalysisRestData {
  return {
    average_moods: {},
    happy_times: {},
    sad_times: {},
    standard_deviation: {}
  }
}

export interface PerContentAggregatedEmotionAnalysisRestRequestParams {
  content_type: ContentType,
  content_object_id: number,
  users?: Array<number>
}

export interface PerContentAggregatedEmotionAnalysisRestData {
  average_moods: number | null,
  standard_deviation: number | null,
  view_time: number | null,
  happy_times: PerMeetingVideoPersonRole<number>,
  sad_times: PerMeetingVideoPersonRole<number>,
}

export function getPlaceholderPerContentAggregatedEmotionAnalysisRestData (): PerContentAggregatedEmotionAnalysisRestData {
  return {
    average_moods: null,
    standard_deviation: null,
    view_time: null,
    happy_times: {},
    sad_times: {}
  }
}
