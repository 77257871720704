import { State } from '@/store/state'
import { Filter, AlertType } from '@/types/internal'
import {
  Customer,
  FormSchema,
  Meeting,
  MeetingForm,
  MeetingTemplate,
  MeetingViewSets,
  Profile,
  Report,
  SlideDeck,
  Topic,
  TourObject
} from '@/types/descript_meeting_rest'

export const mutations = {
  /**
   * adds a Message Alert which will be displayed at the current view
   *
   * @example
   * this.$store.commit('addImmediateAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addImmediateAlert (state: State, payload: { msg: string; type?: AlertType; autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = true
    }

    state.immediateAlerts.push({ msg: payload.msg, type: payload.type, autoHide: payload.autoHide })
  },
  clearImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  clearAndDismissImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  /**
   * adds an Alert for the next visible view. The function will handle itself, that every unique alert message
   * will only be displayed once
   *
   * @example
   * this.$store.commit('addPendingAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addPendingAlert (state: State, payload: { msg: string; type?: AlertType; autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = true
    }

    // A pending alert must only be added if there is not already an exact
    // same message in the list of pending alerts. Adding the same alert could
    // for example happen when multiple requests to the REST api are done and
    // they fail with a non authenticated error.

    let alreadyThere = false
    for (const pendingAlert of state.pendingAlerts) {
      if (pendingAlert.msg === payload.msg && pendingAlert.type === payload.type) {
        alreadyThere = true
      }
    }

    if (!alreadyThere) {
      state.pendingAlerts.push({ msg: payload.msg, type: payload.type, autoHide: payload.autoHide })
    }
  },
  clearPendingAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.pendingAlerts.splice(0, state.pendingAlerts.length)
  },
  setAvailableMeetingsFilter (state: State, payload: Array<Filter>): void {
    state.availableMeetingsFilter = payload
  },
  ensureMeetingsList (state: State, payload: { listKey: string, urlPart?: MeetingViewSets }): void {
    if (!Object.prototype.hasOwnProperty.call(state.meetingsWithSearchAndFilterState, payload.listKey)) {
      if (!payload.urlPart) {
        payload.urlPart = MeetingViewSets.Meeting
      }

      state.meetingsWithSearchAndFilterState[payload.listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<Meeting>,
        urlPart: payload.urlPart
      }
    }
  },
  setMeetingsSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.meetingsWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  addMeetingsFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetings = state.meetingsWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < meetings.filters.length; index++) {
      if (payload.filter.group === meetings.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetings.filters.splice(found, 1)
    }

    meetings.filters.push(payload.filter)
  },
  removeMeetingsFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetings = state.meetingsWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < meetings.filters.length; index++) {
      if (payload.filter.key === meetings.filters[index].key &&
          payload.filter.value === meetings.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetings.filters.splice(found, 1)
    }
  },
  ensureValidityOfMeetingsFilter (state: State): void {
    for (const listKey in state.meetingsWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.meetingsWithSearchAndFilterState, listKey)) {
        const meetings = state.meetingsWithSearchAndFilterState[listKey]
        const indexesToRemove = []

        // The order is from end of list to beginning on purpose so that we
        // fill the indexesToRemove array with indexes sorted from high to low
        // so that the later coming splice calls shrink the active filters array
        // from the end and not from the beginning.
        for (let index = meetings.filters.length - 1; index >= 0; index--) {
          let found = false

          for (const availableFilter of state.availableMeetingsFilter) {
            if (meetings.filters[index].key === availableFilter.key &&
                meetings.filters[index].value === availableFilter.value) {
              found = true
              break
            }
          }

          if (!found) {
            indexesToRemove.push(index)
          }
        }

        for (const indexToRemove of indexesToRemove) {
          meetings.filters.splice(indexToRemove, 1)
        }
      }
    }
  },
  resetMeetingsFilter (state: State, listKey: string): void {
    const meetings = state.meetingsWithSearchAndFilterState[listKey]

    // splice with this parameters is the performant way to empty an array
    meetings.filters.splice(0, meetings.filters.length)
  },
  setMeetings (state: State, payload: { listKey: string, meetings: Array<Meeting> }): void {
    const meetings = state.meetingsWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    meetings.instances.splice(0, meetings.instances.length)

    for (const meeting of payload.meetings) {
      meetings.instances.push(
        meeting)
    }
  },
  removeMeeting (state: State, payload: Meeting): void {
    for (const listKey in state.meetingsWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.meetingsWithSearchAndFilterState, listKey)) {
        const meetings = state.meetingsWithSearchAndFilterState[listKey]
        let indexToRemove = -1

        for (let index = 0; index < meetings.instances.length; index++) {
          if (meetings.instances[index].id === payload.id) {
            indexToRemove = index
            break
          }
        }

        if (indexToRemove >= 0) {
          meetings.instances.splice(indexToRemove, 1)
        }
      }
    }
  },
  setNextMeeting (state: State, payload: Meeting | null): void {
    state.nextMeeting = payload
  },
  setAvailableSlideDeckFilter (state: State, payload: Array<Filter>): void {
    state.availableSlideDeckFilter = payload
  },
  ensureSlideDecksList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.slideDecksWithSearchAndFilterState, listKey)) {
      state.slideDecksWithSearchAndFilterState[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<SlideDeck>,
        urlPart: ''
      }
    }
  },
  setSlideDecksSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.slideDecksWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  addSlideDecksFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const slideDecks = state.slideDecksWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < slideDecks.filters.length; index++) {
      if (payload.filter.group === slideDecks.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      slideDecks.filters.splice(found, 1)
    }

    slideDecks.filters.push(payload.filter)
  },
  removeSlideDecksFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const slideDecks = state.slideDecksWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < slideDecks.filters.length; index++) {
      if (payload.filter.key === slideDecks.filters[index].key &&
          payload.filter.value === slideDecks.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      state.slideDecksWithSearchAndFilterState[payload.listKey].filters.splice(found, 1)
    }
  },
  ensureValidityOfSlideDecksFilter (state: State): void {
    for (const listKey in state.slideDecksWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.slideDecksWithSearchAndFilterState, listKey)) {
        const slideDecks = state.slideDecksWithSearchAndFilterState[listKey]
        const indexesToRemove = []

        // The order is from end of list to beginning on purpose so that we
        // fill the indexesToRemove array with indexes sorted from high to low
        // so that the later coming splice calls shrink the active filters array
        // from the end and not from the beginning.
        for (let index = slideDecks.filters.length - 1; index >= 0; index--) {
          let found = false

          for (const availableFilter of state.availableSlideDeckFilter) {
            if (slideDecks.filters[index].key === availableFilter.key &&
                slideDecks.filters[index].value === availableFilter.value) {
              found = true
              break
            }
          }

          if (!found) {
            indexesToRemove.push(index)
          }
        }

        for (const indexToRemove of indexesToRemove) {
          slideDecks.filters.splice(indexToRemove, 1)
        }
      }
    }
  },
  resetSlideDecksFilter (state: State, listKey: string): void {
    const slideDecks = state.slideDecksWithSearchAndFilterState[listKey]

    // splice with this parameters is the performant way to empty an array
    slideDecks.filters.splice(0, slideDecks.filters.length)
  },
  /**
   * fills the state of the store with SlideDeck instances
   */
  setSlideDecks (state: State, payload: { listKey: string, slideDecks: Array<SlideDeck> }): void {
    const slideDecks = state.slideDecksWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    slideDecks.instances.splice(0, slideDecks.instances.length)

    for (const slideDeck of payload.slideDecks) {
      slideDecks.instances.push(
        slideDeck)
    }
  },
  /**
   * removes one SlideDeck from global state
   */
  removeSlideDeck (state: State, slideDeckId: number): void {
    for (const listKey in state.slideDecksWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.slideDecksWithSearchAndFilterState, listKey)) {
        const slideDecks = state.slideDecksWithSearchAndFilterState[listKey]
        let indexToRemove = -1

        for (let index = 0; index < slideDecks.instances.length; index++) {
          if (slideDecks.instances[index].id === slideDeckId) {
            indexToRemove = index
            break
          }
        }

        if (indexToRemove >= 0) {
          slideDecks.instances.splice(indexToRemove, 1)
        }
      }
    }
  },
  setTopics (state: State, payload: Array<Topic>): void {
    // splice with this parameters is the performant way to empty an array
    state.topics.splice(0, state.topics.length)

    for (const topic of payload) {
      state.topics.push(topic)
    }
  },
  setReports (state: State, payload: Array<Report>): void {
    // splice with this parameters is the performant way to empty an array
    state.reports.splice(0, state.reports.length)

    for (const report of payload) {
      state.reports.push(report)
    }
  },

  setAvailableFormSchemaFilter (state: State, payload: Array<Filter>): void {
    state.availableFormSchemaFilter = payload
  },
  ensureFormSchemasList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.formSchemasWithSearchAndFilterState, listKey)) {
      state.formSchemasWithSearchAndFilterState[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<FormSchema>,
        urlPart: ''
      }
    }
  },
  setFormSchemasSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.formSchemasWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  addFormSchemasFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const forms = state.formSchemasWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < forms.filters.length; index++) {
      if (payload.filter.group === forms.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      forms.filters.splice(found, 1)
    }

    forms.filters.push(payload.filter)
  },
  removeFormSchemasFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const forms = state.formSchemasWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < forms.filters.length; index++) {
      if (payload.filter.key === forms.filters[index].key &&
        payload.filter.value === forms.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      state.formSchemasWithSearchAndFilterState[payload.listKey].filters.splice(found, 1)
    }
  },
  ensureValidityOfFormSchemasFilter (state: State): void {
    for (const listKey in state.formSchemasWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.formSchemasWithSearchAndFilterState, listKey)) {
        const forms = state.formSchemasWithSearchAndFilterState[listKey]
        const indexesToRemove = []

        // The order is from end of list to beginning on purpose so that we
        // fill the indexesToRemove array with indexes sorted from high to low
        // so that the later coming splice calls shrink the active filters array
        // from the end and not from the beginning.
        for (let index = forms.filters.length - 1; index >= 0; index--) {
          let found = false

          for (const availableFilter of state.availableFormSchemaFilter) {
            if (forms.filters[index].key === availableFilter.key &&
              forms.filters[index].value === availableFilter.value) {
              found = true
              break
            }
          }

          if (!found) {
            indexesToRemove.push(index)
          }
        }

        for (const indexToRemove of indexesToRemove) {
          forms.filters.splice(indexToRemove, 1)
        }
      }
    }
  },
  resetFormSchemasFilter (state: State, listKey: string): void {
    const forms = state.formSchemasWithSearchAndFilterState[listKey]

    // splice with this parameters is the performant way to empty an array
    forms.filters.splice(0, forms.filters.length)
  },
  setFormSchemas (state: State, payload: { listKey: string, formSchemas: Array<FormSchema> }): void {
    const formSchemas = state.formSchemasWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    formSchemas.instances.splice(0, formSchemas.instances.length)

    for (const formSchema of payload.formSchemas) {
      formSchemas.instances.push(
        formSchema)
    }
  },
  ensureMeetingFormsList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.meetingFormsWithSearchAndFilterState, listKey)) {
      state.meetingFormsWithSearchAndFilterState[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<MeetingForm>,
        urlPart: ''
      }
    }
  },
  setMeetingFormsSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.meetingFormsWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  addMeetingFormsFilter (state: State, payload:{ listKey: string, filter: Filter }): void {
    const meetingForms = state.meetingFormsWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < meetingForms.filters.length; index++) {
      if (payload.filter.group === meetingForms.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetingForms.filters.splice(found, 1)
    }

    meetingForms.filters.push(payload.filter)
  },
  setMeetingForms (state: State, payload: { listKey: string, meetingForms: Array<MeetingForm> }): void {
    const meetingForms = state.meetingFormsWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    meetingForms.instances.splice(0, meetingForms.instances.length)

    for (const meetingForm of payload.meetingForms) {
      meetingForms.instances.push(meetingForm)
    }
  },
  ensureCustomerList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.customersWithSearchAndFilterState, listKey)) {
      state.customersWithSearchAndFilterState[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<Customer>,
        urlPart: ''
      }
    }
  },
  setCustomers (state: State, payload: { listKey: string, customers: Array<Customer> }): void {
    const customers = state.customersWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    customers.instances.splice(0, customers.instances.length)

    for (const customer of payload.customers) {
      customers.instances.push(
        customer)
    }
  },
  setCustomersSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.customersWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  setAvailableCustomersFilter (state: State, payload: Array<Filter>): void {
    state.availableCustomersFilter = payload
  },
  ensureValidityOfCustomersFilter (state: State): void {
    for (const listKey in state.customersWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.customersWithSearchAndFilterState, listKey)) {
        const customers = state.customersWithSearchAndFilterState[listKey]
        const indexesToRemove = []

        // The order is from end of list to beginning on purpose so that we
        // fill the indexesToRemove array with indexes sorted from high to low
        // so that the later coming splice calls shrink the active filters array
        // from the end and not from the beginning.
        for (let index = customers.filters.length - 1; index >= 0; index--) {
          let found = false

          for (const availableFilter of state.availableCustomersFilter) {
            if (customers.filters[index].key === availableFilter.key &&
                customers.filters[index].value === availableFilter.value) {
              found = true
              break
            }
          }

          if (!found) {
            indexesToRemove.push(index)
          }
        }

        for (const indexToRemove of indexesToRemove) {
          customers.filters.splice(indexToRemove, 1)
        }
      }
    }
  },
  removeCustomersFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const customers = state.customersWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < customers.filters.length; index++) {
      if (payload.filter.key === customers.filters[index].key &&
          payload.filter.value === customers.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      customers.filters.splice(found, 1)
    }
  },
  resetCustomersFilter (state: State, listKey: string): void {
    const customers = state.customersWithSearchAndFilterState[listKey]

    // splice with this parameters is the performant way to empty an array
    customers.filters.splice(0, customers.filters.length)
  },
  addCustomersFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const customers = state.customersWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < customers.filters.length; index++) {
      if (payload.filter.group === customers.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      customers.filters.splice(found, 1)
    }

    customers.filters.push(payload.filter)
  },
  setAvailableMeetingTemplatesFilter (state: State, payload: Array<Filter>): void {
    state.availableMeetingTemplatesFilter = payload
  },
  ensureMeetingTemplatesList (state: State, listKey: string): void {
    if (!Object.prototype.hasOwnProperty.call(state.meetingTemplatesWithSearchAndFilterState, listKey)) {
      state.meetingTemplatesWithSearchAndFilterState[listKey] = {
        searchTerm: '',
        filters: [] as Array<Filter>,
        instances: [] as Array<MeetingTemplate>,
        urlPart: ''
      }
    }
  },
  setMeetingTemplatesSearchTerm (state: State, payload: { listKey: string, searchTerm: string }): void {
    state.meetingTemplatesWithSearchAndFilterState[payload.listKey].searchTerm = payload.searchTerm
  },
  addMeetingTemplatesFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < meetingTemplates.filters.length; index++) {
      if (payload.filter.group === meetingTemplates.filters[index].group) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetingTemplates.filters.splice(found, 1)
    }

    meetingTemplates.filters.push(payload.filter)
  },
  removeMeetingTemplatesFilter (state: State, payload: { listKey: string, filter: Filter }): void {
    const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[payload.listKey]
    let found = -1

    for (let index = 0; index < meetingTemplates.filters.length; index++) {
      if (payload.filter.key === meetingTemplates.filters[index].key &&
          payload.filter.value === meetingTemplates.filters[index].value) {
        found = index
        break
      }
    }

    if (found >= 0) {
      meetingTemplates.filters.splice(found, 1)
    }
  },
  ensureValidityOfMeetingTemplatesFilter (state: State): void {
    for (const listKey in state.meetingTemplatesWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.meetingTemplatesWithSearchAndFilterState, listKey)) {
        const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[listKey]
        const indexesToRemove = []

        // The order is from end of list to beginning on purpose so that we
        // fill the indexesToRemove array with indexes sorted from high to low
        // so that the later coming splice calls shrink the active filters array
        // from the end and not from the beginning.
        for (let index = meetingTemplates.filters.length - 1; index >= 0; index--) {
          let found = false

          for (const availableFilter of state.availableMeetingTemplatesFilter) {
            if (meetingTemplates.filters[index].key === availableFilter.key &&
                meetingTemplates.filters[index].value === availableFilter.value) {
              found = true
              break
            }
          }

          if (!found) {
            indexesToRemove.push(index)
          }
        }

        for (const indexToRemove of indexesToRemove) {
          meetingTemplates.filters.splice(indexToRemove, 1)
        }
      }
    }
  },
  resetMeetingTemplatesFilter (state: State, listKey: string): void {
    const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[listKey]

    // splice with this parameters is the performant way to empty an array
    meetingTemplates.filters.splice(0, meetingTemplates.filters.length)
  },
  setMeetingTemplates (state: State, payload: { listKey: string, meetingTemplates: Array<MeetingTemplate> }): void {
    const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[payload.listKey]

    // splice with this parameters is the performant way to empty an array
    meetingTemplates.instances.splice(0, meetingTemplates.instances.length)

    for (const meetingTemplate of payload.meetingTemplates) {
      meetingTemplates.instances.push(
        meetingTemplate)
    }
  },
  removeMeetingTemplate (state: State, payload: MeetingTemplate): void {
    for (const listKey in state.meetingTemplatesWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.meetingTemplatesWithSearchAndFilterState, listKey)) {
        const meetingTemplates = state.meetingTemplatesWithSearchAndFilterState[listKey]
        let indexToRemove = -1

        for (let index = 0; index < meetingTemplates.instances.length; index++) {
          if (meetingTemplates.instances[index].id === payload.id) {
            indexToRemove = index
            break
          }
        }

        if (indexToRemove >= 0) {
          meetingTemplates.instances.splice(indexToRemove, 1)
        }
      }
    }
  },
  removeMeetingForm (state: State, meetingFormId: number): void {
    for (const listKey in state.meetingFormsWithSearchAndFilterState) {
      if (Object.prototype.hasOwnProperty.call(state.meetingFormsWithSearchAndFilterState, listKey)) {
        const meetingForms = state.meetingFormsWithSearchAndFilterState[listKey]
        let indexToRemove = -1

        for (let index = 0; index < meetingForms.instances.length; index++) {
          if (meetingForms.instances[index].id === meetingFormId) {
            indexToRemove = index
            break
          }
        }

        if (indexToRemove >= 0) {
          meetingForms.instances.splice(indexToRemove, 1)
        }
      }
    }
  },
  setUserProfile (state: State, payload: Profile | null): void {
    state.profile = payload
  },
  setTourObjects (state: State, tourObjects: Array<TourObject>): void {
    for (const tour of tourObjects) {
      state.tourObjects[tour.tour_id] = tour
    }
  },
  updateTourObject (state: State, payload: { tourKey: string, tourObject: TourObject }): void {
    state.tourObjects[payload.tourKey] = payload.tourObject
  }
}
