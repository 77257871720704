import { nextTick } from 'vue'
import { Dropdown } from 'bootstrap'
import { OnboardingTourModule } from '@/types/internal'
import router from '@/router'

async function clickMeetingActions () {
  const buttonElem: HTMLElement | null = document.querySelector('div[id^=\'meeting-actions-\'] > button')
  if (buttonElem) {
    buttonElem.click()
    await nextTick()
  }
}

function modifyBootstrapDropdownInstance (element: HTMLElement, attributeName: string, value: string) {
  const dropdownInstance: Dropdown | null = Dropdown.getInstance(element)
  if (dropdownInstance) {
    element.setAttribute(attributeName, value)
    dropdownInstance.dispose()
    Dropdown.getOrCreateInstance(element)
  }
}

// Helper to allow loading the tours multiple times during the lifetime of the app.
// This is needed so that the viewport width based adjustment of the placement is not
// only done once on the first load of the JavaScript file of our app. At the moment
// tour related components have been adjusted to call this helper during component
// creation which ensures that on entering a view the tour tooltips always fit to the
// current browser window size. On purpose this tour loading is not attached to the
// resize event so that in view window browser size changes are not reflected in tour
// tooltips placement.
export function getOnBoardingTours (): Record<string, OnboardingTourModule> {
  let mediumScreen = window.screen.width < 992
  if (window.visualViewport) {
    mediumScreen = window.visualViewport.width < 992
  }

  const onBoardingTours: Record<string, OnboardingTourModule> = {
    addMeetingButton: {
      dependsOn: [],
      steps: [
        { target: '#toMeetingAddButton' } // 0
      ],
      list: false
    },
    meetingAddForm: {
      dependsOn: [],
      steps: [
        {
          target: '#customer-dropdown', // 0
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: 'input[name=\'title\']', // 1
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: 'input[name=\'location\']', // 2
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: 'div[name=\'start\']', // 3
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: '#topics-dropdown', // 4
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: 'button[type=\'submit\']', // 5
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        }
      ],
      list: true
    },
    meetingAddTemplateField: {
      dependsOn: ['meetingAddForm'],
      steps: [
        {
          target: '#js-meeting-template-dropdown-button',
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        }
      ],
      list: false
    },
    meetingList: {
      dependsOn: ['meetingAddForm', 'addMeetingButton'],
      steps: [
        {
          target: '#search',
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#meetingListFilterBar',
          params: {
            enableScrolling: false
          }
        },
        {
          target: 'div[id^=\'meeting-actions-\']',
          params: {
            enableScrolling: false
          },
          before:
            async function (): Promise<void> {
              const buttonElem: HTMLElement | null = document.querySelector('div[id^=\'meeting-actions-\'] > button')
              if (buttonElem) {
                // this is needed to prevent the dropdown from closing on the same click event the tour is advanced with
                modifyBootstrapDropdownInstance(buttonElem, 'data-bs-auto-close', 'inside')
              }
            }
        },
        {
          target: 'div[id^=\'meeting-actions-\'] > ul > li:nth-child(2)',
          params: {
            enableScrolling: false,
            placement: 'left'
          },
          before: async function (): Promise<void> {
            await clickMeetingActions()
          }
        },
        {
          target: 'div[id^=\'meeting-actions-\'] > ul > li:nth-child(5)',
          params: {
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: 'div[id^=\'meeting-actions-\'] > ul > li:nth-child(6)',
          params: {
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: 'div[id^=\'meeting-actions-\'] > ul > li:nth-child(9)',
          params: {
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: 'div[id^=\'meeting-actions-\'] > ul > li:nth-child(12)',
          params: {
            enableScrolling: false,
            placement: 'left'
          }
        },
        {
          target: 'a[id^=\'meeting-quick-link\']',
          params: {
            enableScrolling: false,
            placement: 'left'
          },
          before: async function (): Promise<void> {
            await clickMeetingActions()
            const buttonElem: HTMLElement | null = document.querySelector('div[id^=\'meeting-actions-\'] > button')
            if (buttonElem) {
              // this is needed to prevent the dropdown from closing on the same click event the tour is advanced with
              modifyBootstrapDropdownInstance(buttonElem, 'data-bs-auto-close', 'inside')
            }
          }
        }
      ],
      list: true
    },
    meetingItems: {
      dependsOn: [],
      steps: [
        {
          target: 'div[id^=\'slide-deck-item-container\']',
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: 'div[class=\'overflow-wrapper\']',
          params: {
            placement: mediumScreen ? 'top' : 'left'
          },
          before: async function (): Promise<void> {
            const buttonElem: HTMLElement | null = document.querySelector(
              'div[id^=\'slide-deck-item-container\'] > div.col-8 > div > div > span')
            if (buttonElem) {
              buttonElem.click()
            }
          }
        },
        {
          target: '#draggable-target',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          }
        }
      ],
      list: true
    },
    meetingPlayerSlide: {
      dependsOn: [],
      steps: [
        {
          target: '#meeting-item-slide-container',
          params: {
            enableScrolling: false,
            placement: 'left',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -300]
                }
              }
            ]
          }
        },
        {
          target: '#next',
          params: {
            enableScrolling: false,
            placement: mediumScreen ? 'top' : 'left'
          }
        },
        {
          target: '#like',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#dislike',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#addNote',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#freehandNote',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: 'div[id^=\'ratio-slide-image-meeting-item\']',
          params: {
            enableScrolling: false,
            placement: 'left',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -300]
                }
              }
            ]
          },
          before: async function (): Promise<void> {
            const buttonElem: HTMLElement | null = document.querySelector('#freehandNote')
            if (buttonElem) {
              buttonElem.click()
            }
          }
        },
        {
          target: '#freehand-note-save',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#freehand-note-leave',
          params: {
            enableScrolling: false,
            placement: 'top'
          }
        },
        {
          target: '#fullscreen',
          params: {
            enableScrolling: false,
            placement: 'top'
          },
          before: async function (): Promise<void> {
            const buttonElem: HTMLElement | null = document.querySelector('#freehand-note-leave')
            if (buttonElem) {
              buttonElem.click()
            }
          }
        }
      ],
      list: true
    },
    meetingPlayerConsentItem: {
      dependsOn: [],
      steps: [
        {
          target: '#meeting-item-slide-container > div',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#recordingForEmotionAnalysisIsActiveSwitch',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#recording-active-indicator',
          params: {
            placement: 'top',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 35]
                }
              }
            ]
          }
        }
      ],
      list: false
    },
    meetingReport: {
      dependsOn: [],
      steps: [
        {
          target: '#report-text-editor-container',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          }
        },
        {
          target: 'button[type=\'submit\']',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#event-container',
          params: {
            placement: mediumScreen ? 'top' : 'left'
          }
        }
      ],
      list: true
    },
    emotionAnalysisVideoUpload: {
      dependsOn: [],
      steps: [
        {
          target: 'input[name=\'file\']',
          params: {
            placement: 'left'
          }
        }
      ],
      list: false
    },
    emotionAnalysisVideoProcessing: {
      dependsOn: [],
      steps: [
        {
          target: '#progress-card-container',
          params: {
            placement: 'left'
          }
        }
      ],
      list: false
    },

    emotionAnalysisPersonIdentification: {
      dependsOn: [],
      steps: [
        {
          target: 'div[id^=\'form-container\'] > button',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          }
        },
        {
          target: 'div[id^=\'form-container\'] > button:nth-of-type(2)',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          }
        },
        {
          target: 'form > .input-group',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          },
          before: async function (): Promise<void> {
            const buttonElem: HTMLElement | null = document.querySelector('div[id^=\'form-container\'] > button')
            if (buttonElem) {
              buttonElem.click()
            }
          }
        },
        {
          target: 'form > .form-floating',
          params: {
            placement: mediumScreen ? 'top' : 'right'
          }
        },
        {
          target: 'form > button',
          params: {
            placement: 'right'
          }
        }
      ],
      list: true
    }

  }

  return onBoardingTours
}
