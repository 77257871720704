/* eslint camelcase: "off" */

import {
  MeetingEmotionAnalysisData,
  MeetingVideoPerson,
  TourStates
} from '@/types/descript_meeting_rest'

/**
 * Types of possible Alerts
 * @enum {string}
 */
export enum AlertType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark'
}

export interface Message {
  msg: string,
  type: AlertType,
  autoHide: boolean,
}

export interface Filter {
  group: string,
  key: string,
  value: string,
  label?: string
}

export interface InstancesWithSearchAndFilterState<T> {
  searchTerm: string,
  filters: Array<Filter>,
  instances: Array<T>,
  urlPart: string
}

export interface FilterGroupData {
  group: string,
  verbose: string,
  verboseNotActive: string
  filters: Array<Filter>,
  activeFilter: Filter | null
}

export enum ViewState {
  undefined,
  editing,
  success,
  errorWithdrawn,
  errorExpired,
  errorUnexpected
}

export enum IdentificationDataDecision {
  Undecided,
  Keep,
  Merge,
  Delete
}

export interface IdentificationData {
  person: MeetingVideoPerson,
  // eslint-disable-next-line camelcase
  merge_into: number | null,
  // eslint-disable-next-line camelcase
  fundamental_decision: IdentificationDataDecision,
  completed: boolean
}

export interface RequestIdentificationData {
  person: number
  name: string,
  role: number,
  // eslint-disable-next-line camelcase
  merge_into: number | null,
  // eslint-disable-next-line camelcase
  marked_for_deletion: boolean
}

export interface OnboardingTourModule {
  dependsOn: Array<string>,
  steps: Array<Record<string, unknown>>,
  list: boolean
}

export interface TourObjectData {
  // eslint-disable-next-line camelcase
  tour_id: string,
  // eslint-disable-next-line camelcase
  tour_state: TourStates
}

export enum MeetingItemContentMode {
  Interactive,
  Reporting,
  DragAndDrop,
  Thumbnail
}

export enum BarChartColours {
   LightRed = '#ec978f',
   DarkRed = '#e00f00',
   LightGreen = '#7ed7c7',
   DarkGreen = '#00a099',
   DarkBlue = '#5f91e8',
   LightBlue = '#0259be'
}

export interface PairCommonValues {
  consultant: number,
  customer: number,
  average_mood: number,
  time_common_positive: number,
  ratio_positive_to_whole: number,
  time_common_negative: number,
  ratio_negative_to_whole: number,
  correlation_aggregated_meeting_items: number
}

function getPairCommonValues (consultant: number, customer: number, allPairsCommonValues: Array<PairCommonValues>): PairCommonValues | null {
  for (const pairCommonValue of allPairsCommonValues) {
    if (pairCommonValue.consultant === consultant && pairCommonValue.customer === customer) {
      return pairCommonValue
    }
  }

  return null
}

function getNewPairCommonValues (consultant: number, customer: number): PairCommonValues {
  return {
    consultant: consultant,
    customer: customer,
    average_mood: 0,
    time_common_positive: 0,
    ratio_positive_to_whole: 0,
    time_common_negative: 0,
    ratio_negative_to_whole: 0,
    correlation_aggregated_meeting_items: 0
  }
}

function iteratePairedCommonValues<Type> (
  data: Record<number, Record<number, Type>>,
  allPairsCommonValues: Array<PairCommonValues>,
  callback: (pairCommonValues: PairCommonValues, dataForPair: Type) => void
) {
  Object.entries(data).forEach(
    ([consultantString, perCustomer]) => {
      const consultant = parseInt(consultantString)

      Object.entries(perCustomer).forEach(
        ([customerString, dataForPair]) => {
          const customer = parseInt(customerString)
          let pairCommonValues = getPairCommonValues(consultant, customer, allPairsCommonValues)

          if (pairCommonValues === null) {
            pairCommonValues = getNewPairCommonValues(consultant, customer)
            allPairsCommonValues.push(pairCommonValues)
          }

          callback(pairCommonValues, dataForPair)
        }
      )
    }
  )
}

export function getAllPairsCommonValues (data: MeetingEmotionAnalysisData): Array<PairCommonValues> {
  const allPairsCommonValues: Array<PairCommonValues> = []

  iteratePairedCommonValues<number>(
    data.combined.average_mood,
    allPairsCommonValues,
    (pairCommonValues: PairCommonValues, averageMood: number) => {
      pairCommonValues.average_mood = averageMood
    }
  )

  if (data.total_time) {
    iteratePairedCommonValues<{ positive: number, negative: number, total: number }>(
      data.combined.times,
      allPairsCommonValues,
      (pairCommonValues: PairCommonValues, times: { positive: number, negative: number, total: number }) => {
        pairCommonValues.time_common_positive = times.positive
        pairCommonValues.ratio_positive_to_whole = times.positive / data.total_time * 100
        pairCommonValues.time_common_negative = times.negative
        pairCommonValues.ratio_negative_to_whole = times.negative / data.total_time * 100
      }
    )
  }

  iteratePairedCommonValues<number>(
    data.combined.correlations,
    allPairsCommonValues,
    (pairCommonValues: PairCommonValues, correlation: number) => {
      pairCommonValues.correlation_aggregated_meeting_items = correlation
    }
  )

  return allPairsCommonValues
}

export interface PairDistances {
  consultant: number,
  customer: number,
  average_distance: number,
  standard_deviation: number,
  time_together: number,
  ratio_together: number,
  time_apart: number,
  ratio_apart: number,
  least_distance_meeting_item: number | null,
  most_distance_meeting_item: number | null
}

function getPairDistances (
  consultant: number,
  customer: number,
  allPairsDistances: Array<PairDistances>
): PairDistances | null {
  for (const pairDistances of allPairsDistances) {
    if (pairDistances.consultant === consultant && pairDistances.customer === customer) {
      return pairDistances
    }
  }

  return null
}

function getNewPairDistances (consultant: number, customer: number): PairDistances {
  return {
    consultant: consultant,
    customer: customer,
    average_distance: 0,
    standard_deviation: 0,
    time_together: 0,
    ratio_together: 0,
    time_apart: 0,
    ratio_apart: 0,
    least_distance_meeting_item: null,
    most_distance_meeting_item: null
  }
}

function iteratePairedDistances<Type> (
  data: Record<number, Record<number, Type>>,
  allPairsDistances: Array<PairDistances>,
  callback: (pairDistances: PairDistances, dataForPair: Type) => void
) {
  Object.entries(data).forEach(
    ([consultantString, perCustomer]) => {
      const consultant = parseInt(consultantString)

      Object.entries(perCustomer).forEach(
        ([customerString, dataForPair]) => {
          const customer = parseInt(customerString)
          let pairDistances = getPairDistances(consultant, customer, allPairsDistances)

          if (pairDistances === null) {
            pairDistances = getNewPairDistances(consultant, customer)
            allPairsDistances.push(pairDistances)
          }

          callback(pairDistances, dataForPair)
        }
      )
    }
  )
}

export function getAllPairsDistances (data: MeetingEmotionAnalysisData): Array<PairDistances> {
  const allPairsDistances: Array<PairDistances> = []

  iteratePairedDistances<number>(
    data.paired.average_distances,
    allPairsDistances,
    (pairDistances: PairDistances, averageDistance: number) => {
      pairDistances.average_distance = averageDistance
    }
  )

  iteratePairedDistances<number>(
    data.paired.distances_standard_deviation,
    allPairsDistances,
    (pairDistances: PairDistances, standardDeviation: number) => {
      pairDistances.standard_deviation = standardDeviation
    }
  )

  if (data.total_time) {
    iteratePairedDistances<{ times_far: number, times_close: number }>(
      data.paired.distances_times,
      allPairsDistances,
      (pairDistances: PairDistances, times: { times_far: number, times_close: number }) => {
        pairDistances.time_together = times.times_close
        pairDistances.ratio_together = times.times_close / data.total_time * 100
        pairDistances.time_apart = times.times_far
        pairDistances.ratio_apart = times.times_far / data.total_time * 100
      }
    )
  }

  iteratePairedDistances<{ min: number | null, max: number | null }>(
    data.paired.min_max_distance_item,
    allPairsDistances,
    (pairDistances: PairDistances, meetingItems: { min: number | null, max: number | null }) => {
      pairDistances.least_distance_meeting_item = meetingItems.min
      pairDistances.most_distance_meeting_item = meetingItems.max
    }
  )

  return allPairsDistances
}

export interface MeetingItemPersonValues {
  meetingItem: number,
  person: number,
  average_mood: number,
  standard_deviation: number,
  time_positive: number,
  relative_positive_mood: number,
  time_negative: number,
  relative_negative_mood: number
}

export interface MeetingItemPairDistances {
  meetingItem: number,
  consultant: number,
  customer: number,
  average_distance: number,
  standard_deviation: number,
  least_distance: number,
  most_distance: number,
  time_together: number,
  ratio_together: number,
  time_apart: number,
  ratio_apart: number
}

export interface MeetingItemValues {
  meetingItem: number,
  time: number,
  persons: Array<MeetingItemPersonValues>,
  pairs: Array<MeetingItemPairDistances>
}

function getMeetingItemValues (
  meetingItem: number,
  allMeetingItemsValues: Record<number, MeetingItemValues>
): MeetingItemValues | null {
  if (Object.prototype.hasOwnProperty.call(allMeetingItemsValues, meetingItem)) {
    return allMeetingItemsValues[meetingItem]
  }

  return null
}

function getNewMeetingItemValues (meetingItem: number): MeetingItemValues {
  return {
    meetingItem: meetingItem,
    time: 0,
    persons: [],
    pairs: []
  }
}

function getMeetingItemPersonValues (
  person: number,
  meetingItemValues: MeetingItemValues
): MeetingItemPersonValues | null {
  for (const candidate of meetingItemValues.persons) {
    if (candidate.person === person) {
      return candidate
    }
  }

  return null
}

function getNewMeetingItemPersonValues (meetingItem: number, person: number): MeetingItemPersonValues {
  return {
    meetingItem: meetingItem,
    person: person,
    average_mood: 0,
    standard_deviation: 0,
    time_positive: 0,
    relative_positive_mood: 0,
    time_negative: 0,
    relative_negative_mood: 0
  }
}

function iteratePerSlideValues<Type> (
  data: Record<number, Array<[number, Type]>>,
  allMeetingItemsValues: Record<number, MeetingItemValues>,
  callback: (
    meetingItemValues: MeetingItemValues,
    meetingItemPersonValues: MeetingItemPersonValues,
    dataForPerson: Type
  ) => void
) {
  Object.entries(data).forEach(
    ([personString, personData]) => {
      const person = parseInt(personString)

      for (const meetingItemTuple of personData) {
        const meetingItem: number = meetingItemTuple[0]
        const meetingItemData: Type = meetingItemTuple[1]

        let meetingItemValues = getMeetingItemValues(meetingItem, allMeetingItemsValues)

        if (meetingItemValues === null) {
          meetingItemValues = getNewMeetingItemValues(meetingItem)
          allMeetingItemsValues[meetingItem] = meetingItemValues
        }

        let meetingItemPersonValues = getMeetingItemPersonValues(person, meetingItemValues)

        if (meetingItemPersonValues === null) {
          meetingItemPersonValues = getNewMeetingItemPersonValues(meetingItem, person)
          meetingItemValues.persons.push(meetingItemPersonValues)
        }

        callback(meetingItemValues, meetingItemPersonValues, meetingItemData)
      }
    }
  )
}

function iteratePerSlideRecords<Type> (
  data: Record<number, Record<number, Type>>,
  allMeetingItemsValues: Record<number, MeetingItemValues>,
  callback: (
    meetingItemValues: MeetingItemValues,
    meetingItemPersonValues: MeetingItemPersonValues,
    dataForPerson: Type
  ) => void
) {
  Object.entries(data).forEach(
    ([personString, personData]) => {
      const person = parseInt(personString)

      Object.entries(personData).forEach(
        ([meetingItemString, meetingItemData]) => {
          const meetingItem = parseInt(meetingItemString)

          let meetingItemValues = getMeetingItemValues(meetingItem, allMeetingItemsValues)

          if (meetingItemValues === null) {
            meetingItemValues = getNewMeetingItemValues(meetingItem)
            allMeetingItemsValues[meetingItem] = meetingItemValues
          }

          let meetingItemPersonValues = getMeetingItemPersonValues(person, meetingItemValues)

          if (meetingItemPersonValues === null) {
            meetingItemPersonValues = getNewMeetingItemPersonValues(meetingItem, person)
            meetingItemValues.persons.push(meetingItemPersonValues)
          }

          callback(meetingItemValues, meetingItemPersonValues, meetingItemData)
        }
      )
    }
  )
}

function getMeetingItemPairDistances (
  consultant: number,
  customer: number,
  meetingItemValues: MeetingItemValues
): MeetingItemPairDistances | null {
  for (const candidate of meetingItemValues.pairs) {
    if (candidate.consultant === consultant && candidate.customer === customer) {
      return candidate
    }
  }

  return null
}

function getNewMeetingItemPairDistances (
  meetingItem: number,
  consultant: number,
  customer: number
): MeetingItemPairDistances {
  return {
    meetingItem: meetingItem,
    consultant: consultant,
    customer: customer,
    average_distance: 0,
    standard_deviation: 0,
    least_distance: 0,
    most_distance: 0,
    time_together: 0,
    ratio_together: 0,
    time_apart: 0,
    ratio_apart: 0
  }
}

function iteratePerSlidePairedValuesRecords<Type> (
  data: Record<number, Record<number, Record<number, Type>>>,
  allMeetingItemsValues: Record<number, MeetingItemValues>,
  callback: (
    meetingItemValues: MeetingItemValues,
    meetingItemPairDistances: MeetingItemPairDistances,
    dataForPair: Type
  ) => void
) {
  Object.entries(data).forEach(
    ([consultantString, perCustomer]) => {
      const consultant = parseInt(consultantString)

      Object.entries(perCustomer).forEach(
        ([customerString, pairData]) => {
          const customer = parseInt(customerString)

          Object.entries(pairData).forEach(
            ([meetingItemString, meetingItemData]) => {
              const meetingItem = parseInt(meetingItemString)

              let meetingItemValues = getMeetingItemValues(meetingItem, allMeetingItemsValues)

              if (meetingItemValues === null) {
                meetingItemValues = getNewMeetingItemValues(meetingItem)
                allMeetingItemsValues[meetingItem] = meetingItemValues
              }

              let meetingItemPairDistances = getMeetingItemPairDistances(
                consultant,
                customer,
                meetingItemValues
              )

              if (meetingItemPairDistances === null) {
                meetingItemPairDistances = getNewMeetingItemPairDistances(meetingItem, consultant, customer)
                meetingItemValues.pairs.push(meetingItemPairDistances)
              }

              callback(meetingItemValues, meetingItemPairDistances, meetingItemData)
            }
          )
        }
      )
    }
  )
}

export function getAllMeetingItemsValues (data: MeetingEmotionAnalysisData): Record<number, MeetingItemValues> {
  const allMeetingItemsValues = {}

  iteratePerSlideValues<number>(
    data.per_slide.average_moods,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPersonValues: MeetingItemPersonValues,
      averageMood: number
    ) => {
      meetingItemPersonValues.average_mood = averageMood
    }
  )

  iteratePerSlideValues<number>(
    data.per_slide.standard_deviation,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPersonValues: MeetingItemPersonValues,
      standardDeviation: number
    ) => {
      meetingItemPersonValues.standard_deviation = standardDeviation
    }
  )

  iteratePerSlideRecords<{ positive: number, negative: number, total: number }>(
    data.per_slide.times,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPersonValues: MeetingItemPersonValues,
      times: { positive: number, negative: number, total: number }
    ) => {
      if (times.total) {
        if (!meetingItemValues.time) {
          meetingItemValues.time = times.total
        }

        meetingItemPersonValues.time_positive = times.positive
        meetingItemPersonValues.relative_positive_mood = times.positive / times.total * 100
        meetingItemPersonValues.time_negative = times.negative
        meetingItemPersonValues.relative_negative_mood = times.negative / times.total * 100
      }
    }
  )

  iteratePerSlidePairedValuesRecords<number>(
    data.paired.average_distances_per_item,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPairDistances: MeetingItemPairDistances,
      averageDistance: number
    ) => {
      meetingItemPairDistances.average_distance = averageDistance
    }
  )

  iteratePerSlidePairedValuesRecords<number>(
    data.paired.distance_standard_deviation_per_item,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPairDistances: MeetingItemPairDistances,
      standardDeviation: number
    ) => {
      meetingItemPairDistances.standard_deviation = standardDeviation
    }
  )

  iteratePerSlidePairedValuesRecords<{ min_distance: number, max_distance: number }>(
    data.paired.distance_min_max_per_item,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPairDistances: MeetingItemPairDistances,
      distances: { min_distance: number, max_distance: number }
    ) => {
      meetingItemPairDistances.least_distance = distances.min_distance
      meetingItemPairDistances.most_distance = distances.max_distance
    }
  )

  // This must come after processing data.per_slide.times which sets the
  // time spend per meeting item which is needed to calculate ratios here.
  iteratePerSlidePairedValuesRecords<{ times_far: number, times_close: number }>(
    data.paired.distance_times_per_item,
    allMeetingItemsValues,
    (
      meetingItemValues: MeetingItemValues,
      meetingItemPairDistances: MeetingItemPairDistances,
      times: { times_far: number, times_close: number }
    ) => {
      if (meetingItemValues.time) {
        meetingItemPairDistances.time_together = times.times_close
        meetingItemPairDistances.ratio_together = times.times_close / meetingItemValues.time * 100
        meetingItemPairDistances.time_apart = times.times_far
        meetingItemPairDistances.ratio_apart = times.times_far / meetingItemValues.time * 100
      }
    }
  )

  return allMeetingItemsValues
}

export enum ChooseFrom {
  ConsultationModules,
  SlideDecks,
  MeetingForms
}

export const CustomerColourSchema = [
  '#44CA67',
  '#FCD303',
  '#9ABAD6',
  '#6610F2',
  '#0DCAF0',
  '#CCC6A5',
  '#FD7E14'
]

export const ConsultantColourSchema = [
  '#0D2129',
  '#0D6EFD',
  '#D63384'
]
